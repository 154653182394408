import BasicDomain from "./BasicDomain";

export default class Event extends BasicDomain {

    static DEFAULTS = {
        description: '',
        start: null,
        end: undefined,
    }

    constructor(props) {
        super('Event', props, Event.DEFAULTS);
    }

    isSavable = () => (
        this.start != null
    );
}

global.Parse.Object.registerSubclass('Event', Event);
