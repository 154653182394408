import BasicDomain from "./BasicDomain";
import * as yup from 'yup';

export default class Employee extends BasicDomain {
    static JOB_NONE = 'NONE';

    static JOB_PLOW_DRIVER = 'PLOW_DRIVER';

    static JOB_SALT_ROUTE = 'SALT_ROUTE';

    static JOB_SIDEWALK = 'SIDEWALK';

    static JOB_LOAD_OPERATOR = 'LOAD_OPERATOR';

    static JOBS = [
        Employee.JOB_NONE,
        Employee.JOB_PLOW_DRIVER,
        Employee.JOB_SALT_ROUTE,
        Employee.JOB_SIDEWALK,
        Employee.JOB_LOAD_OPERATOR,
    ]

    static JOB_LABELS = {
        [Employee.JOB_NONE]: 'None',
        [Employee.JOB_PLOW_DRIVER]: 'Plow Driver',
        [Employee.JOB_SALT_ROUTE]: 'Salt Route',
        [Employee.JOB_SIDEWALK]: 'Sidewalk',
        [Employee.JOB_LOAD_OPERATOR]: 'Loader Operator',
    }

    static TYPE_USER = 'USER';

    static TYPE_ADMIN = 'ADMIN';

    static TYPES = [
        Employee.TYPE_USER,
        Employee.TYPE_ADMIN,
    ]

    static TYPE_LABELS = {
        [Employee.TYPE_USER]: 'User',
        [Employee.TYPE_ADMIN]: 'Admin',
    }

    static DEFAULTS = {
        name: '',
        pic: null,
        type: Employee.TYPE_USER,
        job: Employee.JOB_NONE,
        phone: '',
        email: '',
        pushNoticeSubscription: null,
        enabledPushNotices: false,
        account: null,
        lastActive: null,
        active: true,
    }

    static VALIDATION_SCHEMA = yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Invalid email format').required('Email is required'),
    }).required();

    constructor(props) {
        super('Employee', props, Employee.DEFAULTS);
    }

    get picUrl() {
        if (!this.pic) {
            return null;
        }

        if (typeof this.pic.url === 'function') {
            return this.pic.url();
        }

        return this.pic.url ?? null;
    }

    isSavable = () => (
        this.name != null
        && this.name.trim() !== ''
        && this.email != null
        && this.email.trim() !== ''
        && Employee.TYPES.includes(this.type)
        && Employee.JOBS.includes(this.job)
    );

    inspect = () => {
        return Employee.VALIDATION_SCHEMA.validate(this, { abortEarly: false });
    }
}

global.Parse.Object.registerSubclass('Employee', Employee);
