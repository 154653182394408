import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import {Box, Button, Sheet, Typography, Chip} from "@mui/joy";

import accountActions from "actions/accountActions";
import SubscriptionNotice from "components/SubscriptionNotice";
import {Account} from "domain";
import CommonUtils from "utils/CommonUtils";



const LabeledValue = ({ label, value }) => {
    return (
        <Typography variant="body1" component="div">
            <Box component="span" sx={{ display: 'inline-block', minWidth: 130 }}>
                {label}:
            </Box>
            {value}
        </Typography>
    );
};

LabeledValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.node.isRequired,
};

const AccountSettings = () => {
    const dispatch = useDispatch();
    const me = useSelector(({ auth }) => auth.me);
    const myAccount = useSelector(({ account }) => account.me);
    const employeeCount = useSelector(({ employees }) => employees.list.length);
    const [isProcessing, setProcessing] = useState(false);
    const isOwner = (me?.id != null && myAccount?.owner?.id != null && me.id === myAccount.owner.id)
    const seatCount = myAccount?.seats ?? 0;
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('sid');

    const billingStatus = myAccount?.billingStatus || Account.BILLING_STATUS_INACTIVE;
    const accountStatus = myAccount?.status || Account.STATUS_INACTIVE;
    let billingColor = 'danger';
    let accountColor = 'danger';

    if (billingStatus === Account.BILLING_STATUS_ACTIVE) {
        billingColor = 'success'
    } else if (billingStatus === Account.BILLING_STATUS_CANCELED) {
        billingColor = 'warning'
    }

    if (accountStatus === Account.STATUS_ACTIVE) {
        accountColor = 'success'
    }

    useEffect(() => {
        if (sessionId) {
            setProcessing(true);
            dispatch(accountActions.updateSubscription(sessionId)).then(() => {
                setProcessing(false);
            })
        }
    }, [sessionId]);

    const handleManageSubscription = () => {
        setProcessing(true);
        dispatch(accountActions.subscribe()).then(res => {
            const url = res.value;

            if (url && url !== '') {
                window.location.href = url;
            }

            setTimeout(() => {
                setProcessing(false);
            }, 500)

        })
    }

    if (!isOwner) {
        return null;
    }

    return (
        <Sheet variant="soft">
            <Box p={2}>
                <Typography level="h5" component="h2" gutterBottom>Account Settings</Typography>
                <LabeledValue label="Name" value={myAccount?.name} />
                <LabeledValue label="Account Status" value={(
                    <Chip
                        color={accountColor}
                        variant="solid"
                        size="sm"
                    >
                        {Account.STATUS_LABELS[accountStatus]}
                    </Chip>
                )} />
                <LabeledValue label="Billing Status" value={(
                    <Chip
                        color={billingColor}
                        variant="solid"
                        size="sm"
                    >
                        {Account.BILLING_STATUS_LABELS[billingStatus]}
                    </Chip>
                )} />
                {(billingStatus === Account.BILLING_STATUS_DEACTIVATED
                    || billingStatus === Account.BILLING_STATUS_CANCELED)
                    ? <LabeledValue label="Deactivation" value={CommonUtils.formatDate(myAccount?.deactivatedAt)} />
                    : null
                }
                <LabeledValue label="Seats Used" value={employeeCount} />
                <LabeledValue label="Seats Owned" value={seatCount} />
                <SubscriptionNotice />
                <Box textAlign="right" p={2}>
                    <Button variant="solid" color="primary" onClick={handleManageSubscription} disabled={isProcessing}>Manage Subscription</Button>
                </Box>
                <Box my={1} align="center">
                    <img src="img/stripe-badge.svg" alt="Powered by Stripe" style={{ width: '80%', maxWidth: 160 }} />
                </Box>
            </Box>
        </Sheet>
    );
}

AccountSettings.propTypes = {};

export default AccountSettings;
