import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@googlemaps/js-api-loader';
import { Sheet, Stack, Table, IconButton, Typography } from "@mui/joy";
import BackIcon from '@mui/icons-material/ArrowBack';
import ForwardIcon from '@mui/icons-material/ArrowForward';

import Config from 'Config';

import CommonUtils from "utils/CommonUtils";
import employeeActions from "actions/employeeActions";
import TablePagination from 'components/TablePagination';
import { Employee } from "domain";

const GOOGLE_API_KEY = Config.googleApiKey;

const EmployeeActivities = ({ employee }) => {
    const [activePeriods, setActivePeriods] = useState([]);
    const [selectedPeriod, setSelectedPeriods] = useState(null);
    const [geopoints, setGeopoints] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const mapRef = useRef(null);


    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    useEffect(() => {
        if (!employee.isNew()) {
            const fetchActivePeriods = async () => {
                const action = await employeeActions.findEmployeeActivePeriods(employee);
                const payload = await action.payload;
                setActivePeriods(payload);
            };

            fetchActivePeriods();
        }
    }, [employee]);

    useEffect(() => {
        if (selectedPeriod) {
            const fetchGeopoints = async () => {
                const action = await employeeActions.findEmployeeLocations(
                    employee, selectedPeriod.from, selectedPeriod.to
                );
                const payload = await action.payload;
                setGeopoints(payload);
            };

            fetchGeopoints();
        } else {
            setGeopoints([]);
        }

        return () => { setGeopoints([]); };
    }, [selectedPeriod]);

    useEffect(() => {
        if (selectedPeriod && geopoints.length > 0) {
            const loader = new Loader({
                apiKey: GOOGLE_API_KEY,
                version: "weekly",
            });

            loader.load().then(() => {
                const map = new window.google.maps.Map(mapRef.current, {
                    center: { lat: geopoints[0].latitude, lng: geopoints[0].longitude },
                    zoom: 12,
                });

                const bounds = new window.google.maps.LatLngBounds();

                geopoints.forEach((geopoint, index) => {
                    const markerPosition = new window.google.maps.LatLng(geopoint.latitude, geopoint.longitude);

                    // Create a marker with a label
                    new window.google.maps.Marker({
                        position: markerPosition,
                        map: map,
                        label: { text: `${index + 1}`, color: "black", fontSize: "12px" },
                        title: CommonUtils.formatDateTime(geopoint.timestamp),
                    });

                    bounds.extend(markerPosition);
                });

                map.fitBounds(bounds);
            });
        }
    }, [selectedPeriod, geopoints]);

    if (activePeriods.length === 0) {
        return null;
    }

    return (
        <Sheet variant="soft" sx={{ p: 2 }}>
            <Typography level="h4">Active Periods</Typography>
            {!selectedPeriod && (
                <Table hoverRow>
                    <thead>
                        <tr>
                            <th style={{width: '45%'}}>From</th>
                            <th style={{width: '45%'}}>To</th>
                            <th style={{width: '10%', textAlign: 'right'}}>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activePeriods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((period, ix) => (
                            <tr key={ix} onClick={() => setSelectedPeriods(period)} style={{cursor: 'pointer'}}>
                                <td>{CommonUtils.formatDateTime(period.from)}</td>
                                <td>{CommonUtils.formatDateTime(period.to)}</td>
                                <td style={{textAlign: 'right'}}>
                                    <IconButton>
                                        <ForwardIcon/>
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <TablePagination
                                    component="div"
                                    count={activePeriods.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </td>
                        </tr>
                    </tfoot>

                </Table>
            )}
            {selectedPeriod && geopoints && (
                <>
                    <Stack direction="row" alignItems="center">
                        <IconButton variant="plain" color="neutral" sx={{justifyContent: "left"}} onClick={() => setSelectedPeriods(null)}>
                            <BackIcon />
                        </IconButton>
                        <Typography level="title-md">
                            {`${CommonUtils.formatDateTime(selectedPeriod.from)} - ${CommonUtils.formatDateTime(selectedPeriod.to)}`}
                        </Typography>
                    </Stack>
                    <div ref={mapRef} style={{ height: '600px', width: '100%' }} />
                </>
            )}
        </Sheet>
    );
};

EmployeeActivities.propTypes = {
    employee: PropTypes.instanceOf(Employee).isRequired,
};

export default EmployeeActivities;
