import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, Button, Menu, MenuItem } from '@mui/joy';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DirectionsIcon from '@mui/icons-material/Route';
import OpenIcon from '@mui/icons-material/Visibility';

import locationActions from 'actions/locationActions';
import {Employee, Location} from 'domain';

import LocationFormDialog from './LocationFormDialog';
import CommonUtils from "utils/CommonUtils";
import {useNavigate, useParams} from "react-router-dom";

const LocationActionMenu = ({ location }) => {
    const dispatch = useDispatch();
    const { locationId } = useParams();
    const me = useSelector(({ employees }) => employees.me);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const navigate = useNavigate();

    const isViewingLocation = locationId === location.id;

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        navigate(`/location/${location.id}`);
        handleMenuClose();
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        handleMenuClose();
    };

    const handleToggleActive = () => {
        CommonUtils.dispatcher(
            locationActions.save(location.set('active', !location.active)),
            dispatch,
            'Location Saved',
            'Failed To Save Location'
        );
        handleMenuClose();
    };

    const handleOpenDirections = () => {
        CommonUtils.openDirections(location.address);
    };

    const handleSave = (myLocation) => {
        CommonUtils.dispatcher(
            locationActions.save(myLocation),
            dispatch,
            'Location Saved',
            'Failed To Save Location'
        );
        setIsDialogOpen(false);
    };

    if (!me || me.type !== Employee.TYPE_ADMIN) {
        return (
            <Button onClick={handleOpenDirections} variant="plain" color="primary">
                <DirectionsIcon /> Directions
            </Button>
        );
    }

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <ClickAwayListener onClickAway={handleMenuClose}>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {!isViewingLocation && <MenuItem onClick={handleOpen}>
                        <OpenIcon/> View
                    </MenuItem>}
                    <MenuItem onClick={handleEdit}>
                        <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem onClick={handleOpenDirections}>
                        <DirectionsIcon /> Directions
                    </MenuItem>
                    <MenuItem onClick={handleToggleActive}>
                        {location.active ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        {location.active ? 'Deactivate' : 'Activate'}
                    </MenuItem>
                </Menu>
            </ClickAwayListener>
            <LocationFormDialog
                key={location.id}
                location={location}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
            />
        </>
    );
};

LocationActionMenu.propTypes = {
    location: PropTypes.instanceOf(Location).isRequired,
};

export default LocationActionMenu;
