import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, Stack, Typography, Avatar, Chip, styled, useTheme} from '@mui/joy';
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import {useMediaQuery} from '@mui/material';

import EmployeeActionMenu from "components/employee/EmployeeActionMenu";
import { Employee, ActivityCriteria } from "domain";
import activityActions from "actions/activityActions";
import AccountSettings from "components/employee/AccountSettings";

import EmployeeActivities from './EmployeeActivities';
import ActivityTable from "components/activity/ActivityTable";
import ActivityFilters from "components/activity/ActivityFilters";

const LinkBox = styled(Box)({
    textDecoration: 'none',
    color: 'var(--joy-palette-primary-plainColor)',
});

const CHUNK_SIZE = 50;

const EmployeeDetails = ({ employee }) => {
    const { id, name, type, job, phone, email, active } = employee;
    const dispatch = useDispatch();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isMore, setMore] = useState(false);
    const [filters, setFilters] = useState(new ActivityCriteria({
        employees: [employee.id]
    }));
    const activities = useSelector(state => state.activity.list);
    const isLoading = useSelector(state => state.activity.isLoading);
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const me = useSelector(({ employees }) => employees.me);

    const isMe = me && me.id === id;

    const handleChangeFilters = ({ startDate, endDate, locations }) => {
        const newCriteria = new ActivityCriteria({
            startDate,
            endDate,
            locations,
            employees: [employee.id],
            sortDir: filters.sortDir,
            sortField: filters.sortField
        })
        setFilters(newCriteria)
    }

    const handleChangeSort = ({ sortDir, sortField }) => {
        const newCriteria = new ActivityCriteria({
            startDate: filters.startDate,
            endDate: filters.endDate,
            locations: filters.locations,
            employees: [employee.id],
            sortDir: sortDir,
            sortField: sortField
        })
        setFilters(newCriteria)
    }

    const handleCheckForMore = (results) => {
        setMore((results.value?.length ?? 0) >= CHUNK_SIZE);
    }

    const handleMore = () => {
        const exclude = [...activities.map(a => a.id)];
        dispatch(activityActions.listByWhereAndWhen(filters, CHUNK_SIZE, exclude, true)).then(handleCheckForMore);
    }

    useEffect(() => {
        if (filters.valid) {
            dispatch(activityActions.listByWhereAndWhen(filters, CHUNK_SIZE)).then(handleCheckForMore);
        }
    }, [filters]);

    useEffect(() => {
        if (employee.id) {
            setFilters(new ActivityCriteria({
                employees: [employee.id]
            }));
        }
    }, [employee.id]);

    useEffect(() => {
        setFilters(new ActivityCriteria({
            employees: [employee.id]
        }));
    }, []);

    return (
        <Box display="flex" flexDirection={{xs: 'column', sm: 'row'}} gap={2}
            alignItems={{xs: 'center', sm: 'flex-start'}}>
            <Box position="relative">
                <Avatar
                    sx={{
                        width: 100,
                        height: 100,
                        mb: 2,
                        filter: active ? 'none' : 'grayscale(100%)',
                    }}
                    color={active ? 'primary' : 'neutral'}
                    variant="outlined"
                    src={employee.picUrl}
                >
                    <PersonIcon fontSize="xl4"/>
                </Avatar>
            </Box>
            <Stack gap={2} flexGrow={1}>
                <Box display="flex" flexDirection="row">
                    <Typography level="h4" component="h1">{name}</Typography>
                    <Box ml={2} flexGrow={1}>
                        <Chip
                            color={type === Employee.TYPE_ADMIN ? "danger" : "primary"}
                            disabled={!active}
                            variant="soft"
                            sx={{"--Chip-paddingInline": "24px"}}
                        >
                            {Employee.TYPE_LABELS[type]}
                        </Chip>
                    </Box>
                    {!active && <Box>
                        <Chip color="danger" variant="soft" sx={{"--Chip-paddingInline": "24px"}}>Inactive</Chip>
                    </Box>}
                    <EmployeeActionMenu employee={employee}/>
                </Box>
                <Typography level="body-md">Job: {Employee.JOB_LABELS[job]}</Typography>
                <Typography level="body-md">
                    Phone: <LinkBox component="a" href={`tel:${phone}`}>{phone}</LinkBox>
                </Typography>
                <Typography level="body-md">
                    Email: <LinkBox component="a" href={`mailto:${email}`}>{email}</LinkBox>
                </Typography>
                {isMe && <AccountSettings/>}
                <EmployeeActivities employee={employee} />
                <ActivityFilters
                    onChange={handleChangeFilters}
                    hideFeilds={['employee']}
                />
                { isXsScreen ? (
                    <ActivityTable
                        activities={activities}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                        sortDir={filters.sortDir}
                        sortField={filters.sortField}
                        handleChangeSort={handleChangeSort}
                        hideFeilds={['employee', 'checkout', 'service', 'photos']}
                    />
                ) : (
                    <ActivityTable
                        activities={activities}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                        sortDir={filters.sortDir}
                        sortField={filters.sortField}
                        handleChangeSort={handleChangeSort}
                        hideFeilds={['employee']}
                    />
                ) }
            </Stack>
        </Box>
    );
};

EmployeeDetails.propTypes = {
    employee: PropTypes.instanceOf(Employee),
};

export default EmployeeDetails;
