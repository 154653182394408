import BasicDomain from "./BasicDomain";

export default class Account extends BasicDomain {
    static STATUS_ACTIVE = 'ACTIVE';

    static STATUS_INACTIVE = 'INACTIVE';

    static STATUSES = [
        Account.STATUS_ACTIVE,
        Account.STATUS_INACTIVE,
    ]

    static STATUS_LABELS = {
        [Account.STATUS_ACTIVE]: 'Active',
        [Account.STATUS_INACTIVE]: 'Inactive',
    }

    static BILLING_STATUS_INACTIVE = 'INACTIVE'; // Meaning this account has never been activated

    static BILLING_STATUS_ACTIVE = 'ACTIVE'; // An active (subscription) account.

    static BILLING_STATUS_CANCELED = 'CANCELED'; // Customer canceled subscription, but account has not been deactivated yet.

    static BILLING_STATUS_DEACTIVATED = 'DEACTIVATED'; // User deactivated their account.

    static BILLING_STATUSES = [
        Account.BILLING_STATUS_ACTIVE,
        Account.BILLING_STATUS_INACTIVE,
        Account.BILLING_STATUS_CANCELED,
        Account.BILLING_STATUS_DEACTIVATED,
    ]

    static BILLING_STATUS_LABELS = {
        [Account.BILLING_STATUS_ACTIVE]: 'Active',
        [Account.BILLING_STATUS_INACTIVE]: 'Inactive',
        [Account.BILLING_STATUS_CANCELED]: 'Canceled',
        [Account.BILLING_STATUS_DEACTIVATED]: 'Deactivated',
    }

    static DEFAULTS = {
        name: '',
        logo: null,
        services: [],
        owner: null,
        seats: 0,
        deactivatedAt: null,
        billingType: '',
        billingStatus: Account.BILLING_STATUS_INACTIVE,
        status: Account.STATUS_ACTIVE,
    }

    constructor(props) {
        super('Account', props, Account.DEFAULTS);
    }
}

global.Parse.Object.registerSubclass('Account', Account);
