import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { Button, styled } from '@mui/joy';
import AddIcon from "@mui/icons-material/Add";

import ActivityFormDialog from "components/activity/ActivityFormDialog";
import CommonUtils from "utils/CommonUtils";
import activityActions from "actions/activityActions";
import {Activity} from "domain";

const BigRoundButton = styled(Button)({
    borderRadius: '50%',
    width: 80,
    height: 80,
    fontSize: 'large',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const BigIcon = styled(AddIcon)({
    fontSize: '3rem',
});

const ActivityBigAddButton = () => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const profile = useSelector(({ employees }) => employees.me);
    const dispatch = useDispatch();

    const handleSave = (myActivity) => {
        CommonUtils.dispatcher(
            activityActions.save(myActivity.employee ? myActivity : myActivity.set('employee', profile)),
            dispatch,
            'Activity Created',
            'Failed To Create Activity'
        ).then((response) => {
            const newActivityId = response?.value?.id;
            if (newActivityId) {
                navigate(`/activity/${newActivityId}`)
            }
        });
        setIsDialogOpen(false);
    };

    return (
        <>
            <ActivityFormDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
                activity={new Activity()}
            />
            <BigRoundButton
                variant="solid"
                color="primary"
                onClick={() => setIsDialogOpen(true)}
            >
                <BigIcon />
            </BigRoundButton>
        </>
    );

};

export default ActivityBigAddButton;
