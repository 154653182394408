import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography, Avatar, Chip, useTheme } from '@mui/joy';
import PropTypes from "prop-types";
import BusinessIcon from '@mui/icons-material/Business';
import {useMediaQuery} from '@mui/material';

import activityActions from "actions/activityActions";
import { Location, ActivityCriteria } from "domain";
import LocationActionMenu from "components/location/LocationActionMenu";
import LocationMapCard from "components/location/LocationMapCard";
import ActivityTable from "components/activity/ActivityTable";
import ActivityFilters from "components/activity/ActivityFilters";

const CHUNK_SIZE = 50;

const LocationDetails = ({ location }) => {
    const { name, address, active, services, point, isPhotosRequired } = location;
    const dispatch = useDispatch();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [isMore, setMore] = useState(false);
    const [filters, setFilters] = useState(new ActivityCriteria({
        locations: [location.id]
    }));
    const activities = useSelector(state => state.activity.list);
    const isLoading = useSelector(state => state.activity.isLoading);
    const serviceOptions = useSelector(({ account }) => account.lov.services);

    const handleChangeFilters = ({ startDate, endDate, locations, employees }) => {
        const newCriteria = new ActivityCriteria({ 
            startDate,
            endDate,
            locations: [location.id],
            employees,
            sortDir: filters.sortDir,
            sortField: filters.sortField
        })
        setFilters(newCriteria)
    }

    const handleChangeSort = ({ sortDir, sortField }) => {
        const newCriteria = new ActivityCriteria({ 
            startDate: filters.startDate,
            endDate: filters.endDate,
            locations: [location.id],
            employees: filters.employees,
            sortDir: sortDir,
            sortField: sortField
        })
        setFilters(newCriteria)
    }

    const handleCheckForMore = (results) => {
        setMore((results.value?.length ?? 0) >= CHUNK_SIZE);
    }

    const handleMore = () => {
        const exclude = [...activities.map(a => a.id)];
        dispatch(activityActions.listByWhereAndWhen(filters, CHUNK_SIZE, exclude, true)).then(handleCheckForMore);
    }

    useEffect(() => {
        if (filters.valid) {
            dispatch(activityActions.listByWhereAndWhen(filters, CHUNK_SIZE)).then(handleCheckForMore);
        }
    }, [filters]);

    useEffect(() => {
        if (location.id) {
            setFilters(new ActivityCriteria({
                locations: [location.id]
            }));
        }
    }, [location.id]);

    useEffect(() => {
        setFilters(new ActivityCriteria({
            locations: [location.id]
        }));
    }, []);
    
    const photoRequirementText = () => (isPhotosRequired ? (
        <Typography level="body-md">
            {`Photo Requirements: ${location.getPhotoRequirementText()}`}
        </Typography>
    ) : null);

    return (
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <Avatar sx={{ width: 100, height: 100, mb: 2 }} color={active ? "primary" : "neutral"} variant="outlined">
                <BusinessIcon fontSize="xl4" />
            </Avatar>
            <Stack gap={2} flexGrow={1}>
                <Box display="flex" flexDirection="row">
                    <Typography level="h4" component="h1" flexGrow={1}>{name}</Typography>
                    {!active && <Box>
                        <Chip color="danger" variant="soft" sx={{"--Chip-paddingInline": "24px"}}>Inactive</Chip>
                    </Box>}
                    <LocationActionMenu location={location} />
                </Box>
                {photoRequirementText()}
                <Typography variant="body1" whiteSpace="pre-line">{address}</Typography>
                <Stack direction="row" gap={2}>
                    {services.map((serviceId) => {
                        return (<Chip key={serviceId} color="primary">{serviceOptions ? serviceOptions[serviceId] : serviceId}</Chip>)
                    })}
                </Stack>
                <LocationMapCard location={location} />
                <Typography level="body-sm" textColor="neutral.500" textAlign="right" noWrap>Latitude: {point?.latitude} / Longitude: {point?.longitude}</Typography>
                <ActivityFilters
                    onChange={handleChangeFilters}
                    hideFeilds={['location']}
                />
                { isXsScreen ? (
                    <ActivityTable
                        activities={activities}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                        sortDir={filters.sortDir}
                        sortField={filters.sortField}
                        handleChangeSort={handleChangeSort}
                        hideFeilds={['location', 'checkout', 'service', 'photos']}
                    />
                ) : (
                    <ActivityTable
                        activities={activities}
                        loading={isLoading}
                        onMore={handleMore}
                        isMore={isMore}
                        sortDir={filters.sortDir}
                        sortField={filters.sortField}
                        handleChangeSort={handleChangeSort}
                        hideFeilds={['location']}
                    />
                ) }
            </Stack>
        </Box>
    );
};

LocationDetails.propTypes = {
    location: PropTypes.instanceOf(Location),
};

export default LocationDetails;
