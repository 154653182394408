import React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, Button, Chip, CircularProgress, Skeleton} from '@mui/joy';
import { useNavigate } from 'react-router-dom';

import { EventArray } from "domain";
import CommonUtils from "utils/CommonUtils";
import OpenIcon from "@mui/icons-material/Visibility";

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
};

const EventTable = ({ events, loading }) => {
    const navigate = useNavigate();

    const handleOpenEvent = (eventId) => () => {
        navigate(`/event/${eventId}`);
    };

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table stickyHeader hoverRow>
                <thead>
                    <tr>
                        <th>When</th>
                        <th>Description</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && Array(5).fill(0).map((v, ix) => (
                        <tr key={ix}>
                            <td><Skeleton width={150} height={18} sx={{ position: 'relative' }} /></td>
                            <td>&nbsp;</td>
                            <td>
                                <Box align="right">
                                    <Button
                                        size="sm"
                                        variant="plain"
                                        color="primary"
                                        disabled
                                    >
                                        <OpenIcon sx={{mr:0.5}} />
                                        View
                                    </Button>
                                </Box>
                            </td>
                        </tr>
                    ))}
                    {events.map((event, ix) => (
                        <tr key={event.id ?? ix} onClick={handleOpenEvent(event.id)} style={{ cursor: 'pointer' }}>
                            <td style={styles.cell}>
                                {event.end ? (
                                    CommonUtils.formatDateRange(event.start, event.end)
                                ) : (
                                    <Chip
                                        label="In Progress"
                                        color="success"
                                        variant="outlined"
                                        sx={{"--Chip-paddingInline": "4px"}}
                                        onClick={handleOpenEvent(event.id)}
                                    >
                                        <div
                                            style={{
                                                "--Chip-paddingInline": "18px",
                                                display: 'flex',
                                                gap: 8,
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingRight: 14,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress size="sm" color="success" variant="solid" />
                                            <span>In Progress</span>
                                        </div>
                                    </Chip>
                                )}
                            </td>
                            <td style={styles.cell}>{event.description}</td>
                            <td>
                                <Box align="right">
                                    <Button
                                        size="sm"
                                        variant="plain"
                                        color="primary"
                                        onClick={handleOpenEvent(event.id)}
                                    >
                                        <OpenIcon sx={{mr:0.5}} />
                                    View
                                    </Button>
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

EventTable.propTypes = {
    events: PropTypes.instanceOf(EventArray).isRequired,
    loading: PropTypes.bool,
};

EventTable.defaultProps = {
    loading: false,
}

export default EventTable;
