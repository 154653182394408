import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';

import { Activity } from 'domain';

const ActivityArchiveButton = ({ activity, onSave }) => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const handleArchive = () => {
        setIsConfirmDialogOpen(false);
        activity.set('status', Activity.STATUS_ARCHIVED);
        onSave(activity);
    };

    if (activity.id == null || activity.status === Activity.STATUS_ARCHIVED) {
        return null;
    }

    return (
        <>
            <Button variant="plain" color="neutral" onClick={() => setIsConfirmDialogOpen(true)}>
                <DeleteIcon />
            </Button>
            <Modal
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
            >
                <ModalDialog>
                    <DialogTitle>
                        Delete Activity?
                    </DialogTitle>
                    <DialogContent>
                        Deleting this activity will remove it from active listings and cannot be undone. Are you sure
                        you want to do this?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="solid"
                            startDecorator={<DeleteIcon />}
                            color="danger"
                            onClick={handleArchive}>Confirm</Button>
                        <Button variant="plain" onClick={() => setIsConfirmDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    );
};

ActivityArchiveButton.propTypes = {
    activity: PropTypes.instanceOf(Activity).isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ActivityArchiveButton;
