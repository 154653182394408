import React from 'react';
import {Box, Stack, Skeleton, Table} from '@mui/joy';

const EventDetailsSkeleton = () => (
    <Box>
        <Stack gap={2}>
            <Skeleton width="50%" height={48} variant="rectangular" />
            <Skeleton width="20%" height={24} variant="rectangular" />
            <Skeleton width="70%" height={128} variant="rectangular" />
            <Box sx={{ maxWidth: '100%' }}>
                <Table stickyHeader>
                    <thead>
                        <tr>
                            <th style={{ width: 50 }}>&nbsp;</th>
                            <th><Skeleton width="100%" height={24} variant="rectangular" /></th>
                            <th><Skeleton width="100%" height={24} variant="rectangular" /></th>
                            <th><Skeleton width="100%" height={24} variant="rectangular" /></th>
                            <th style={{ width: 50 }}>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td><Skeleton width="100%" height={32} variant="rectangular" /></td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </Table>
            </Box>
        </Stack>
    </Box>
);

export default EventDetailsSkeleton;
