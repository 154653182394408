import React from 'react';
import {Box, Stack, Typography} from "@mui/joy";

const NoLocationsMessage = () => (
    <Box sx={{ width: '100%', height: 300, overflow: 'hidden', borderRadius: 'md', textAlign: 'center', backgroundColor: 'background.level1' }}>
        <Stack p={3}>
            <Typography level="h3" color="info">
                No Locations
            </Typography>
            <Typography level="body-md" variant="plain" color="info">
                Add a location to get started
            </Typography>
        </Stack>
    </Box>
);

NoLocationsMessage.propTypes = {};

export default NoLocationsMessage;
