import React from 'react';
import {Box, Skeleton, Stack} from "@mui/joy";

const ActivityDetailsSkeleton = () => (
    <Box display="flex" flexDirection="row" gap={2}>
        <Skeleton width={100} height={100} variant="circular"/>
        <Stack gap={2} flexGrow={1}>
            <Skeleton width="50%" height={48} variant="rectangular"/>
            <Skeleton width="60%" height={32} variant="rectangular"/>
            <Skeleton width="30%" height={32} variant="rectangular"/>
            <Skeleton width="30%" height={32} variant="rectangular"/>
        </Stack>
    </Box>
);

ActivityDetailsSkeleton.propTypes = {};

export default ActivityDetailsSkeleton;
