import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, ModalClose, Box, Button } from '@mui/joy';

import ActivityForm from './ActivityForm';
import ActivityArchiveButton from './ActivityArchiveButton';
import { Activity } from "domain";

const ActivityFormDialog = ({ open, onClose, onSave, activity }) => {
    const [myActivity, setActivity] = useState(new Activity(activity));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setActivity(new Activity(myActivity).set(key, val ?? evt?.target?.value));
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = (event) => {
        event?.preventDefault();
        onSave(myActivity);
    };

    const handleClose = () => {
        setActivity(new Activity(myActivity).reset());
        onClose();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                layout="center"
                variant="soft"
                aria-labelledby="modal-title"
                maxWidth={720}
                sx={{ width: '80vw' }}
            >
                <ModalClose onClick={handleClose} />
                <DialogTitle id="modal-title">
                    {activity.id ? 'Edit Activity' : 'Add Activity'}
                </DialogTitle>
                <DialogContent>
                    <ActivityForm onSave={handleSubmit} onChange={handleChange} activity={myActivity} />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }} orientation="horizontal">
                    <ActivityArchiveButton activity={myActivity} onSave={onSave}/>
                    <Box>
                        <Button variant="plain" color="neutral" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="primary" onClick={handleSubmit} disabled={!myActivity.isSavable() || !myActivity.isDirty()}>
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

ActivityFormDialog.propTypes = {
    activity: PropTypes.instanceOf(Activity),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ActivityFormDialog;
