import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Table, Skeleton, Button, Stack, Avatar, Typography } from '@mui/joy';
import PersonIcon from '@mui/icons-material/Person';

import { Employee } from 'domain';
import TimeStatus from "components/TimeStatus";

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
};

const EmployeeList = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const employees = useSelector(({ employees }) => employees.list);
    const isLoading = useSelector(({ employees }) => employees.isLoading);
    const navigate = useNavigate();

    // Calculate the current page employees
    const indexOfLastEmployee = currentPage * pageSize;
    const indexOfFirstEmployee = indexOfLastEmployee - pageSize;
    const currentEmployees = employees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleRowClick = (employeeId) => {
        navigate(`/employee/${employeeId}`);
    };

    const totalPages = Math.ceil(employees.length / pageSize);

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table
                stickyHeader
                hoverRow
            >
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>&nbsp;</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Last Active</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <tr>
                            <td><Skeleton variant="circular" height={32} /></td>
                            <td><Skeleton variant="rectangular" height={32} /></td>
                            <td><Skeleton variant="rectangular" height={32} /></td>
                            <td><Skeleton variant="rectangular" height={32} /></td>
                        </tr>
                    )}
                    {currentEmployees.length === 0 && (
                        <tr style={{ backgroundColor: 'inherit' }}>
                            <td colSpan={4}>
                                <Typography textAlign="center" display="inline-block" width="100%" level="body-sm">
                                No Employees
                                </Typography>
                            </td>
                        </tr>
                    )}
                    {currentEmployees.map((employee, index) => (
                        <tr key={index} onClick={() => handleRowClick(employee.id)} style={{ cursor: 'pointer' }}>
                            <td><Avatar src={employee.pic?.url()}><PersonIcon /></Avatar></td>
                            <td style={styles.cell}>{employee.name}</td>
                            <td style={styles.cell}>{Employee.JOB_LABELS[employee.job]}</td>
                            <td style={styles.cell}><TimeStatus date={employee.lastActive} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {totalPages > 1 && (
                <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button key={index} onClick={() => paginate(index + 1)} disabled={totalPages === 1}>
                            {index + 1}
                        </Button>
                    ))}
                </Stack>
            )}
        </Box>
    );
};

EmployeeList.propTypes = {
    pageSize: PropTypes.number,
}

EmployeeList.defaultProps = {
    pageSize: 10,
}

export default EmployeeList;
