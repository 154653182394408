import Activity from './Activity';
import BasicArray from './BasicArray';
import CommonUtils from "utils/CommonUtils";

export default class ActivityArray extends BasicArray {
    get myItemClass() { return Activity; }

    constructor(items = []) {
        super(items);
    }

    toCSV = () => {
        const headers = ['Activity ID', 'Location', 'Employee', 'Status', 'Services', 'Address', 'Check-in', 'Check-out', 'Requested', 'Created', 'Last Updated', 'Photo Count', 'Notes'];
        const rows = this.map(activity => [
            CommonUtils.escapeCSVField(activity.id ?? 'N/A'),
            CommonUtils.escapeCSVField(activity.location?.name ?? 'N/A'),
            CommonUtils.escapeCSVField(activity.employee?.name ?? 'N/A'),
            CommonUtils.escapeCSVField(Activity.STATUS_LABELS[activity.status]),
            CommonUtils.escapeCSVField(activity.services?.join(', ') ?? 'N/A'),
            CommonUtils.escapeCSVField(activity.location?.address ?? 'N/A'),
            CommonUtils.escapeCSVField(CommonUtils.formatDateTime(activity.checkin)),
            CommonUtils.escapeCSVField(CommonUtils.formatDateTime(activity.checkout)),
            CommonUtils.escapeCSVField(CommonUtils.formatDateTime(activity.requestedAt)),
            CommonUtils.escapeCSVField(CommonUtils.formatDateTime(activity.createdAt)),
            CommonUtils.escapeCSVField(CommonUtils.formatDateTime(activity.updatedAt)),
            CommonUtils.escapeCSVField(`${activity.photoCount ?? 0}`),
            CommonUtils.escapeCSVField(activity.notes ?? ' '),
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(e => e.join(','))
        ].join('\n');

        return csvContent;
    };
}
