import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import reportWebVitals from './reportWebVitals';
import {initialize} from 'utils/ParseUtils';
import createAppStore from './store';
import theme from './theme';

import Authorize from 'components/Authorize';
import Layout from 'components/Layout';
import DashboardView from 'views/DashboardView';
import ActivitiesView from 'views/activity/ActivitiesView';
import ActivityView from 'views/activity/ActivityView';
import EventView from 'views/event/EventView';
import EventsView from 'views/event/EventsView';
import LocationView from 'views/location/LocationView';
import LocationsView from 'views/location/LocationsView';
import EmployeeView from 'views/employee/EmployeeView';
import EmployeesView from 'views/employee/EmployeesView';
import SubscriptionView from 'views/subscription/SubscriptionView';
import NotFound from 'views/NotFound';

initialize();
const store = createAppStore();
const root = createRoot(document.getElementById('root'));

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .catch(function(err) {
            console.error('Service Worker Registration Failed', err);
        });
}

root.render(
    <Provider store={store}>
        <CssVarsProvider theme={theme} defaultMode="dark">
            <CssBaseline />
            <BrowserRouter>
                <Authorize>
                    <Layout>
                        <Routes>
                            <Route exact path="/" element={<DashboardView />} />
                            <Route path="/location" element={<LocationsView />} />
                            <Route path="/location/:locationId" element={<LocationView />} />
                            <Route path="/employee" element={<EmployeesView />} />
                            <Route path="/employee/:employeeId" element={<EmployeeView />} />
                            <Route path="/event" element={<EventsView />} />
                            <Route path="/event/:eventId" element={<EventView />} />
                            <Route path="/activity" element={<ActivitiesView />} />
                            <Route path="/activity/:activityId" element={<ActivityView />} />
                            <Route path="/dashboard/:activityId" element={<ActivityView />} />
                            <Route path="/subscription" element={<SubscriptionView />} />
                            <Route path="/subscription/:event" element={<SubscriptionView />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Layout>
                </Authorize>
            </BrowserRouter>
        </CssVarsProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
