import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {Stack, Textarea, FormControl, FormLabel, Input, Select, Option, Switch} from '@mui/joy';

import { Location} from 'domain';
import RequiredIndicator from "components/RequiredIndicator";

const LocationForm = ({ onSave, onChange, location = {} }) => {
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const serviceOptionIds = Object.keys(serviceOptions ?? {});

    const handleToggle = (key) => (event) => {
        onChange(key)(null, event.target.checked);
    }

    const handleChangeInt = (key) => (event) => {
        let value = parseInt(event.target.value, 10);
        value = isNaN(value) ? 1 : Math.abs(value);

        onChange(key)(null, value);
    }

    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
                <FormLabel>Name<RequiredIndicator /></FormLabel>
                <Input
                    color="neutral"
                    placeholder="Location Name"
                    size="lg"
                    variant="outlined"
                    value={location.name}
                    onChange={onChange('name')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Address<RequiredIndicator /></FormLabel>
                <Textarea
                    color="neutral"
                    placeholder="Address"
                    size="lg"
                    variant="outlined"
                    value={location.address}
                    onChange={onChange('address')}
                    minRows={3}
                    maxRows={8}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Services</FormLabel>
                <Select
                    size="lg"
                    variant="outlined"
                    value={location.services}
                    onChange={onChange('services')}
                    multiple
                >
                    {serviceOptionIds.map((serviceId) => (
                        <Option key={serviceId} value={serviceId}>{serviceOptions[serviceId]}</Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl
                orientation="horizontal"
            >
                <FormLabel>Require Before/After Photos?</FormLabel>
                <Switch
                    checked={location.isPhotosRequired}
                    onChange={handleToggle('isPhotosRequired')}
                    color={location.isPhotosRequired ? 'success' : 'neutral'}
                    variant={location.isPhotosRequired ? 'solid' : 'outlined'}
                    endDecorator={location.isPhotosRequired ? 'Yes' : 'No'}
                />
            </FormControl>

            {location.isPhotosRequired && (
                <Stack direction="row" spacing={2}>
                    <FormControl sx={{flexGrow: 1}}>
                        <FormLabel>Minimum Before Photos</FormLabel>
                        <Input
                            type="number"
                            value={location.minBeforePhotos}
                            onChange={handleChangeInt('minBeforePhotos')}
                            slotProps={{
                                input: {
                                    min: 0,
                                },
                            }}
                            size="lg"
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl sx={{flexGrow: 1}}>
                        <FormLabel>Minimum After Photos</FormLabel>
                        <Input
                            type="number"
                            value={location.minAfterPhotos}
                            onChange={handleChangeInt('minAfterPhotos')}
                            slotProps={{
                                input: {
                                    min: 0,
                                },
                            }}
                            size="lg"
                            variant="outlined"
                        />
                    </FormControl>
                </Stack>
            )}

        </Stack>
    );
};

LocationForm.propTypes = {
    location: PropTypes.instanceOf(Location),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default LocationForm;
