import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {
    Modal,
    ModalDialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ModalClose,
    Button,
    Typography,
    Select,
    Option,
    FormControl, FormLabel
} from '@mui/joy';

import { EventLocationArray, ActivityArray, Activity } from 'domain';

const ActivityDispatcher = ({ eventLocations, onSubmit, onClose, open }) => {
    const employees = useSelector(({ employees }) => employees.list);
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const serviceOptionIds = Object.keys(serviceOptions ?? {});
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);

    const handleEmployeeChange = (evt, val) => {
        setSelectedEmployee(val ?? null);
    };

    const handleServicesChange = (e, val) => {
        setSelectedServices(val ?? [])
    }

    const handleCancel = () => {
        setSelectedEmployee(null);
        onClose();
    };

    const handleSubmit = () => {
        const activities = new ActivityArray([...eventLocations].map((eventLocation) => {
            return new Activity()
                .set('location', eventLocation.location)
                .set('employee', employees.get(selectedEmployee).toPointer())
                .set('services', selectedServices)
                .set('requestedAt', new Date())
        }))
        onSubmit(activities);
        onClose();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                layout="center"
                variant="soft"
                aria-labelledby="activity-dispatcher-title"
                maxWidth={720}
                sx={{width: '80vw'}}
            >
                <ModalClose onClick={handleCancel} />
                <DialogTitle id="activity-dispatcher-title">
                    Dispatch Employee To Location
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Dispatch an employee to these {eventLocations.length} locations.
                    </Typography>

                    <FormControl>
                        <FormLabel>Employee</FormLabel>
                        <Select
                            label="Select Employee"
                            value={selectedEmployee || ''}
                            onChange={handleEmployeeChange}
                        >
                            {employees.map((employee) => (
                                <Option key={employee.id} value={employee.id}>
                                    {employee.name}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Services</FormLabel>
                        <Select
                            size="lg"
                            variant="outlined"
                            value={selectedServices}
                            onChange={handleServicesChange}
                            multiple
                        >
                            {serviceOptionIds.map((serviceId) => (
                                <Option key={serviceId} value={serviceId}>{serviceOptions[serviceId]}</Option>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="primary" onClick={handleSubmit} disabled={selectedEmployee == null}>
                        Dispatch
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

ActivityDispatcher.propTypes = {
    eventLocations: PropTypes.instanceOf(EventLocationArray).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ActivityDispatcher;
