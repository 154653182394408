import ActivityPicture from './ActivityPicture';
import BasicArray from './BasicArray';

export default class ActivityPictureArray extends BasicArray {
    get myItemClass() { return ActivityPicture; }

    constructor(items = []) {
        super(items);
    }
}
