// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/event/EventMeter.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,8BAA8B;IAClC;AACJ","sourcesContent":["@keyframes move {\n    0% {\n        background-position: 0 0;\n    }\n    100% {\n        background-position: 50px 50px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
