import React from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {Checkbox, Stack, Chip} from "@mui/joy";

import {ActivityArray, EventLocation, Activity} from "domain";
import CommonUtils from 'utils/CommonUtils';

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
};

const EventLocationTableRow = ({ eventLocation, activities, selected, onSelect }) => {
    const navigate = useNavigate();

    const getStatus = () => {
        if (activities.length === 0) {
            return (
                <Chip color="danger" variant="soft">Service Needed</Chip>
            )
        }
        const isInProgress = activities.some(act => act.status !== Activity.STATUS_COMPLETE);

        if (isInProgress) {
            return (
                <Chip color="warning" variant="soft">Dispatched</Chip>
            )
        }

        return (
            <Chip color="success" variant="soft">Serviced</Chip>
        );
    };

    const getEmployees = () => {
        if (activities.length === 0) {
            return (
                <Chip color="danger" variant="soft">
                    Unassigned
                </Chip>
            )
        }

        return activities.map(activity => activity.employee.name ?? '...').join(', ');
    };

    const getActivityStatus = () => {
        if (activities.length === 0) {
            return (
                <Chip color="neutral" variant="soft">
                    None
                </Chip>
            )
        }

        return activities.map((activity) => (
            <Chip 
                key={activity.id} 
                color={Activity.STATUS_COLORS[activity.status] ?? "neutral"} 
                variant="soft"
                onClick={() => {navigate(`/activity/${activity.id}`)}}
            >
                {Activity.STATUS_LABELS[activity.status] ?? 'N/A'}
            </Chip>
        ));
    };

    const getLastCheckin = () => {
        const checkins = activities
            .map(activity => activity.checkin)
            .filter(ci => ci != null)
            .sort((a, b) => b.getTime() - a.getTime());

        if (checkins.length === 0) {
            return 'N/A';
        }

        return CommonUtils.formatDateTime(checkins[0]);
    };


    return (
        <tr>
            <td>
                <Checkbox
                    checked={selected}
                    onClick={() => onSelect(eventLocation)}
                />
            </td>
            <td style={styles.cell}>{eventLocation.location?.get('name')}</td>
            <td>{getStatus()}</td>
            <td>
                {getEmployees()}
            </td>
            <td>
                <Stack spacing={1}>
                    {getActivityStatus()}
                </Stack>
            </td>
            <td>{getLastCheckin()}</td>
        </tr>
    );
}

EventLocationTableRow.propTypes = {
    eventLocation: PropTypes.instanceOf(EventLocation).isRequired,
    activities: PropTypes.instanceOf(ActivityArray).isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
};

EventLocationTableRow.defaultProps = {
    selected: false,
    onSelect: () => {},
};

export default EventLocationTableRow;
