import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import { Box, Button } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";

import CommonUtils from "utils/CommonUtils";
import activityActions from "actions/activityActions";

import ActivityFormDialog from "./ActivityFormDialog";
import { Activity } from "domain";

const AddActivityButton = () => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const profile = useSelector(({ employees }) => employees.me);
    const dispatch = useDispatch();

    const handleSave = (myActivity) => {
        CommonUtils.dispatcher(
            activityActions.save(myActivity.employee ? myActivity : myActivity.set('employee', profile)),
            dispatch,
            'Activity Created',
            'Failed To Create Activity'
        ).then((response) => {
            const newActivityId = response?.value?.id;
            if (newActivityId) {
                navigate(`/activity/${newActivityId}`)
            }
        });
        setIsDialogOpen(false);
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <ActivityFormDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
                activity={new Activity()} // Assuming you have a constructor for Activity
            />
            <Button onClick={() => setIsDialogOpen(true)} variant="soft">
                <AddIcon/>
                Add
            </Button>
        </Box>
    );
}

export default AddActivityButton;
