import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Box, Table, Skeleton, Button, Stack, Avatar, Typography} from '@mui/joy';
import BusinessIcon from '@mui/icons-material/Business';

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
};

const LocationList = ({ pageSize }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const locations = useSelector(({ locations }) => locations.list);
    const isLoading = useSelector(({ locations }) => locations.isLoading);
    const navigate = useNavigate();

    // Calculate the current page locations
    const indexOfLastLocation = currentPage * pageSize;
    const indexOfFirstLocation = indexOfLastLocation - pageSize;
    const currentLocations = locations.slice(indexOfFirstLocation, indexOfLastLocation);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleRowClick = (locationId) => {
        navigate(`/location/${locationId}`);
    };

    const totalPages = Math.ceil(locations.length / pageSize);

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table
                stickyHeader
                hoverRow
            >
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>&nbsp;</th>
                        <th>Name</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <tr>
                            <td><Skeleton variant="circular" height={32} /></td>
                            <td><Skeleton variant="rectangular" height={32} /></td>
                            <td><Skeleton variant="rectangular" height={32} /></td>
                        </tr>
                    )}
                    {currentLocations.length === 0 && (
                        <tr style={{ backgroundColor: 'inherit' }}>
                            <td colSpan={3}>
                                <Typography textAlign="center" display="inline-block" width="100%" level="body-sm">
                                    No Locations
                                </Typography>
                            </td>
                        </tr>
                    )}
                    {currentLocations.map((location, index) => (
                        <tr key={index} onClick={() => handleRowClick(location.id)} style={{ cursor: 'pointer' }}>
                            <td><Avatar><BusinessIcon /></Avatar></td>
                            <td style={styles.cell}>{location.name}</td>
                            <td style={styles.cell}>{location.address}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {totalPages > 1 && (
                <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button key={index} onClick={() => paginate(index + 1)} disabled={totalPages === 1}>
                            {index + 1}
                        </Button>
                    ))}
                </Stack>
            )}
        </Box>
    );
};

LocationList.propTypes = {
    pageSize: PropTypes.number,
}

LocationList.defaultProps = {
    pageSize: 10,
}

export default LocationList;
