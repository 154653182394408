import Employee from './Employee';
import BasicArray from './BasicArray';

export default class EmployeeArray extends BasicArray {
    get myItemClass() { return Employee; }

    constructor(items = []) {
        super(items);
    }
}
