import React from 'react';
import { Box, Stack, Skeleton } from '@mui/joy';

const LocationDetailsSkeleton = () => (
    <Box display="flex" flexDirection="row" gap={2}>
        <Skeleton width={100} height={100} variant="circular"/>
        <Stack gap={2} flexGrow={1}>
            <Skeleton width="50%" height={48} variant="rectangular"/>
            <Skeleton width="33%" height={128} variant="rectangular"/>
            <Skeleton width="100%" height={300} variant="rectangular"/>
            <Skeleton width="100%" height={48} variant="rectangular"/>
        </Stack>
    </Box>
);

export default LocationDetailsSkeleton;
