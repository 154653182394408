import BasicDomain from "./BasicDomain";

export default class Activity extends BasicDomain {

    static STATUS_PENDING = 'PENDING';

    static STATUS_IN_PROGRESS = 'IN_PROGRESS';

    static STATUS_COMPLETE = 'COMPLETE';

    static STATUS_ARCHIVED = 'ARCHIVED';

    static STATUS_BILLED = 'BILLED';

    static STATUSES = [
        Activity.STATUS_PENDING,
        Activity.STATUS_IN_PROGRESS,
        Activity.STATUS_COMPLETE,
        Activity.STATUS_BILLED,
        Activity.STATUS_ARCHIVED,
    ]

    static STATUSES_INCOMPLETE = [
        Activity.STATUS_PENDING,
        Activity.STATUS_IN_PROGRESS,
    ]

    static STATUS_LABELS = {
        [Activity.STATUS_PENDING]: 'Pending',
        [Activity.STATUS_IN_PROGRESS]: 'In Progress',
        [Activity.STATUS_COMPLETE]: 'Completed',
        [Activity.STATUS_BILLED]: 'Billed',
        [Activity.STATUS_ARCHIVED]: 'Archived',
    }

    static STATUS_COLORS = {
        [Activity.STATUS_PENDING]: 'warning',
        [Activity.STATUS_IN_PROGRESS]: 'primary',
        [Activity.STATUS_COMPLETE]: 'success',
        [Activity.STATUS_BILLED]: 'neutral',
        [Activity.STATUS_ARCHIVED]: 'danger',
    }

    static DEFAULTS = {
        location: null,
        employee: null,
        notes: '',
        photoCount: 0,
        requestedAt: new Date(),
        createdAt: null,
        updatedAt: null,
        checkin: null,
        checkout: null,
        checkinGeo: null,
        checkoutGeo: null,
        services: [],
        status: Activity.STATUS_PENDING,
    }

    constructor(props) {
        super('Activity', props, Activity.DEFAULTS);
    }


    isSavable = () => (
        this.location != null
        && Activity.STATUSES.includes(this.status)
    );
}

global.Parse.Object.registerSubclass('Activity', Activity);
