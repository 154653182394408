import React from 'react';
import {Avatar, Card, CardContent, CardOverflow, Stack, Skeleton} from "@mui/joy";
import ArrowIcon from "@mui/icons-material/DoubleArrow";

const ActivityListItemSkeleton = () => (
    <Card
        variant="outlined"
        orientation="horizontal"
        color="neutral"
    >
        <CardContent>
            <Stack direction="column" gap={2}>
                <Skeleton width="100%" height={32} variant="rectangular"/>
                <Skeleton width="70%" height={24} variant="rectangular"/>
                <Stack direction="row" gap={2}>
                    <Skeleton width={64} height={24} variant="rectangular"/>
                    <Skeleton width={64} height={24} variant="rectangular"/>
                </Stack>
            </Stack>
        </CardContent>
        <CardOverflow
            variant="soft"
            color="neutral"
            sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
        >
            <Avatar color="neutral">
                <ArrowIcon/>
            </Avatar>
        </CardOverflow>
    </Card>
);

ActivityListItemSkeleton.propTypes = {};

export default ActivityListItemSkeleton;
