import React from 'react';
import Config from 'Config';

import {Card} from "@mui/joy";
import PropTypes from "prop-types";
import {Location} from "domain";

const { googleApiKey } = Config;

const LocationMapCard = ({ location }) => {
    const { name, point } = location;
    const mapUrl = point
        ? `https://maps.googleapis.com/maps/api/staticmap?center=${point.latitude},${point.longitude}&zoom=14&size=600x300&markers=color:0xf59e0b%7C${point.latitude},${point.longitude}&key=${googleApiKey}`
        : `https://maps.googleapis.com/maps/api/staticmap?&zoom=0&size=600x300&key=${googleApiKey}`;

    return (
        <Card sx={{m: 0, p: 0, width: '100%', height: '300px', overflow: 'hidden', alignItems: 'center'}}>
            <img src={mapUrl} alt={`Map of ${name}`}
                style={{width: '100%', height: '100%', maxWidth: 600, objectFit: 'cover'}}/>
        </Card>
    );
}

LocationMapCard.propTypes = {
    location: PropTypes.instanceOf(Location).isRequired,
};

export default LocationMapCard;
