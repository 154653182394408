// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes pulse-warning {
    0% {
        opacity: 1;
        box-shadow: 0 0 0 0 rgba(251, 191, 36, 0.4);
    }
    70% {
        opacity: 0.7;
        box-shadow: 0 0 0 20px rgba(251, 191, 36, 0);
    }
    100% {
        opacity: 1;
        box-shadow: 0 0 0 0 rgba(251, 191, 36, 0);
    }
}

@keyframes pulse-success {
    0% {
        opacity: 1;
        box-shadow: 0 0 0 0 rgba(52, 211, 153, 0.4);
    }
    70% {
        opacity: 0.7;
        box-shadow: 0 0 0 30px rgba(52, 211, 153, 0);
    }
    100% {
        opacity: 1;
        box-shadow: 0 0 0 0 rgba(52, 211, 153, 0);
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/activity/ActivityAnimations.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,2CAA2C;IAC/C;IACA;QACI,YAAY;QACZ,4CAA4C;IAChD;IACA;QACI,UAAU;QACV,yCAAyC;IAC7C;AACJ;;AAEA;IACI;QACI,UAAU;QACV,2CAA2C;IAC/C;IACA;QACI,YAAY;QACZ,4CAA4C;IAChD;IACA;QACI,UAAU;QACV,yCAAyC;IAC7C;AACJ","sourcesContent":["@keyframes pulse-warning {\n    0% {\n        opacity: 1;\n        box-shadow: 0 0 0 0 rgba(251, 191, 36, 0.4);\n    }\n    70% {\n        opacity: 0.7;\n        box-shadow: 0 0 0 20px rgba(251, 191, 36, 0);\n    }\n    100% {\n        opacity: 1;\n        box-shadow: 0 0 0 0 rgba(251, 191, 36, 0);\n    }\n}\n\n@keyframes pulse-success {\n    0% {\n        opacity: 1;\n        box-shadow: 0 0 0 0 rgba(52, 211, 153, 0.4);\n    }\n    70% {\n        opacity: 0.7;\n        box-shadow: 0 0 0 30px rgba(52, 211, 153, 0);\n    }\n    100% {\n        opacity: 1;\n        box-shadow: 0 0 0 0 rgba(52, 211, 153, 0);\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
