import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalDialog, DialogTitle, DialogContent, DialogActions, ModalClose, Button } from '@mui/joy';
import LocationForm from './LocationForm';

import { Location } from "domain";

const LocationFormDialog = ({ open, onClose, onSave, location }) => {
    const [myLocation, setLocation] = useState(new Location(location));
    const [errors, setErrors] = useState({});

    const handleChange = (key) => (evt, val) => {
        setLocation(new Location(myLocation).set(key, val ?? evt.target.value));
        setErrors({ ...errors, [key]: undefined });
    }

    const handleSubmit = (event) => {
        event?.preventDefault();
        onSave(myLocation);
    };

    const handleClose = () => {
        setLocation(new Location(myLocation).reset());
        onClose();
    };

    return (
        <Modal open={open}>
            <ModalDialog
                layout="center"
                variant="soft"
                aria-labelledby="modal-title"
                maxWidth={720}
                sx={{width:'80vw'}}
            >
                <ModalClose onClick={handleClose} />
                <DialogTitle id="modal-title">
                    {location ? 'Edit Location' : 'Add Location'}
                </DialogTitle>
                <DialogContent>
                    <LocationForm onSave={handleSubmit} onChange={handleChange} location={myLocation} />
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="primary" onClick={handleSubmit} disabled={!myLocation.isSavable() || !myLocation.isDirty()}>
                        Save
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

LocationFormDialog.propTypes = {
    location: PropTypes.instanceOf(Location),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default LocationFormDialog;
