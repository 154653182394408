import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {Box, Stack, Typography, Chip, Button, Sheet, Alert} from '@mui/joy';
import {useTheme} from "@mui/joy/styles";
import PropTypes from "prop-types";
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import DirectionsIcon from "@mui/icons-material/Route";
import WarningIcon from '@mui/icons-material/Warning';
import { Loader } from '@googlemaps/js-api-loader';

import Config from 'Config';

import CommonUtils from "utils/CommonUtils";
import appActions from "actions/appActions";
import activityPictureActions from "actions/activityPictureActions";
import employeeActions from "actions/employeeActions";
import ActivityActionMenu from "components/activity/ActivityActionMenu";
import FileUploader from 'components/FileUploader';
import {Activity, Message, ActivityPictureArray, ActivityPicture} from "domain";

import ActivityPhotos from "components/activity/ActivityPhotos";
import ActivityQuickForm from "components/activity/ActivityQuickForm";
import ActivityPhotoPrompt from "components/activity/ActivityPhotoPrompt";

const GOOGLE_API_KEY = Config.googleApiKey;

const ActivityDetails = ({ activity, pictures }) => {
    const { employee, location, checkin, checkout, status, services } = activity;
    const theme = useTheme();
    const dispatch = useDispatch();
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const profile = useSelector(({ employees }) => employees.me);
    const [uploadingBefore, setUploadingBefore] = useState(false);
    const [uploadingAfter, setUploadingAfter] = useState(false);
    const [photoPromptType, setPhotoPromptType] = useState(null);
    const [geopoints, setGeopoints] = useState([]);
    const mapRef = useRef(null);
    const isArchived = status === Activity.STATUS_ARCHIVED;

    const beforePics = pictures.filter(pic => pic.type === ActivityPicture.TYPE_BEFORE);
    const afterPics = pictures.filter(pic => pic.type === ActivityPicture.TYPE_AFTER);
    const otherPics = pictures.filter(pic => pic.type !== ActivityPicture.TYPE_BEFORE
        && pic.type !== ActivityPicture.TYPE_AFTER);
    const hasBeforePhotos = beforePics.length > 0;
    const hasAfterPhotos = afterPics.length > 0;

    const isCheckoutEnabled = location?.isPhotosRequired === true
        ? (beforePics.length >= location.minBeforePhotos && afterPics.length >= location.minAfterPhotos)
        : true;

    const markerPath = 'M 11 0.09 C 6.778 0.09 3.363 3.505 3.363 7.727 C 3.363 13.454 11 21.909 11 21.909 C 11 21.909 18.636 13.454 18.636 7.727 C 18.636 3.505 15.222 0.09 11 0.09 Z M 11 10.454 C 9.494 10.454 8.272 9.232 8.272 7.727 C 8.272 6.221 9.494 4.999 11 4.999 C 12.505 4.999 13.727 6.221 13.727 7.727 C 13.727 9.232 12.505 10.454 11 10.454 Z';

    const handleUpload = (type) => (image, metadata) => {
        const newPic = new ActivityPicture()
            .set('activity', activity)
            .set('image', image)
            .set('type', type)
            .set('metadata', metadata);

        CommonUtils.dispatcher(
            activityPictureActions.save(newPic),
            dispatch,
            'Photo Uploaded.',
            'Failed to upload this photo.',
        )
        setUploadingBefore(false);
        setUploadingAfter(false);
    };

    const handleError = (error) => {
        dispatch(appActions.addNotice(new Message({ title: error, type: Message.TYPE_ERROR })))
        setUploadingBefore(false);
        setUploadingAfter(false);
    };

    const triggerUploadBefore = () => {
        setUploadingBefore(true);
    };

    const triggerUploadAfter = () => {
        setUploadingAfter(true);
    };

    const handleUpdate = (mode) => {
        if (mode === "checkin" && !hasBeforePhotos) {
            setPhotoPromptType('before');
        } else if (mode === "checkout" && !hasAfterPhotos) {
            setPhotoPromptType('after');
        }
    }

    const closePhotoPrompt = () => {
        setPhotoPromptType(null);
    };

    const addPhotos = (type) => {
        if (type === 'before') {
            triggerUploadBefore();
        } else if (type === 'after') {
            triggerUploadAfter();
        }
    }

    useEffect(() => {
        if (location && location.name == null) {
            location.fetch()
        }
    }, [])

    useEffect(() => {
        if (employee) {
            const fetchGeopoints = async () => {
                const action = await employeeActions.findEmployeeLocations(
                    employee, checkin, checkout ?? new Date(), false
                );
                const payload = await action.payload;
                setGeopoints(payload);
            };

            fetchGeopoints();
        }
    }, [employee]);


    useEffect(() => {
        const loader = new Loader({
            apiKey: GOOGLE_API_KEY,
            version: "weekly",
        });

        loader.load().then(() => {
            const locationLatLng = new window.google.maps.LatLng(location.point.latitude, location.point.longitude);

            const map = new window.google.maps.Map(mapRef.current, {
                center: locationLatLng,
                zoom: 21,
            });

            new window.google.maps.Marker({
                position: locationLatLng,
                map: map,
                title: location.name,
                icon: {
                    path: markerPath,
                    fillColor: theme.palette.primary[500],
                    fillOpacity: 0.8,
                    scale: 2,
                    strokeColor: theme.palette.primary[700],
                    strokeWeight: 2,
                    anchor: new window.google.maps.Point(11, 22),
                },
            });

            geopoints.forEach(geopoint => {
                new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(geopoint.latitude, geopoint.longitude),
                    map: map,
                    title: CommonUtils.formatDateTime(geopoint.timestamp),
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 12,
                        fillColor: theme.palette.primary['500'],
                        fillOpacity: 1,
                        strokeWeight: 0,
                    },
                });
            });

            const pathPoints = geopoints.map(point => new window.google.maps.LatLng(point.latitude, point.longitude));

            const path = new window.google.maps.Polyline({
                path: pathPoints,
                geodesic: true,
                strokeColor: theme.palette.primary['500'],
                strokeOpacity: 0.3,
                strokeWeight: 8,
            });

            path.setMap(map);

            const bounds = new window.google.maps.LatLngBounds();
            pathPoints.forEach(point => bounds.extend(point));
            bounds.extend(locationLatLng);
            map.fitBounds(bounds);

        });
    }, [location, geopoints]);

    if (!profile) {
        return null;
    }

    return (
        <Stack gap={2}>
            <Box display="flex" flexDirection="row">
                <Box ml={2} flexGrow={1}>
                    <Chip
                        color={Activity.STATUS_COLORS[status]}
                        variant="soft"
                        sx={{"--Chip-paddingInline": "24px"}}
                    >
                        {Activity.STATUS_LABELS[status]}
                    </Chip>
                </Box>
                <ActivityActionMenu activity={activity} enableCheckout={isCheckoutEnabled} />
            </Box>

            <Typography
                level="h3"
                flexGrow={1}
                sx={{
                    textDecoration: isArchived ? 'line-through' : 'none'
                }}
            >
                {location?.name || 'N/A'}
            </Typography>

            {profile.id !== employee?.id && <Typography level="body-md" component="span">Employee: {employee?.name || 'N/A'}</Typography>}
            <Typography level="body-md" component="span">Services:
                {services.length > 0
                    ? services.map((serviceId) => (serviceOptions ? serviceOptions[serviceId] : serviceId)).join(', ')
                    : <Typography color="neutral" sx={{fontStyle: 'italic'}}> No Service Needed</Typography>
                }
            </Typography>
            <Typography level="body-md" component="span">Checked In: {checkin ? CommonUtils.formatDateTime(checkin) : 'N/A'}</Typography>
            {checkin && (
                <Typography level="body-md" component="span">Checked Out: {checkout ? CommonUtils.formatDateTime(checkout) : 'N/A'}</Typography>
            )}

            {profile.id === employee?.id ? (
                <ActivityQuickForm activity={activity} onUpdate={handleUpdate} enableCheckout={isCheckoutEnabled} />
            ) : (
                <Typography level="body-md" component="span">
                    Notes:
                    <Sheet sx={{ p: 2, whiteSpace: 'pre-wrap' }}>
                        {activity.notes}
                    </Sheet>
                </Typography>
            )}

            <Typography level="h4">Before Photos</Typography>
            <ActivityPhotos pictures={beforePics} />
            {(location?.isPhotosRequired === true && beforePics.length < location.minBeforePhotos) ? (
                <Alert
                    color="warning"
                    variant="soft"
                    startDecorator={<WarningIcon />}
                >
                    {`Location Requires ${location.minBeforePhotos} "Before Photos".`}
                </Alert>
            ) : null}
            <Box align="center">
                <Button
                    startDecorator={<AddPhotoIcon />}
                    onClick={triggerUploadBefore}
                    variant="soft"
                >
                    Add Before Photo
                </Button>
                <FileUploader
                    open={uploadingBefore}
                    onUpload={handleUpload(ActivityPicture.TYPE_BEFORE)}
                    onError={handleError}
                    types={['image/*']}
                    maxSize={20971520} // 20MB
                />
            </Box>

            {(afterPics.length > 0 || checkin) ? (<>
                <Typography level="h4">After Photos</Typography>
                <ActivityPhotos pictures={afterPics}/>
                {(location?.isPhotosRequired === true && afterPics.length < location.minAfterPhotos) ? (
                    <Alert
                        color="warning"
                        variant="soft"
                        startDecorator={<WarningIcon />}
                    >
                        {`Location Requires ${location.minBeforePhotos} "After Photos".`}
                    </Alert>
                ) : null}
                <Box align="center">
                    <Button
                        startDecorator={<AddPhotoIcon/>}
                        onClick={triggerUploadAfter}
                        variant="soft"
                    >
                        Add After Photo
                    </Button>
                    <FileUploader
                        open={uploadingAfter}
                        onUpload={handleUpload(ActivityPicture.TYPE_AFTER)}
                        onError={handleError}
                        types={['image/*']}
                        maxSize={20971520} // 20MB
                    />
                </Box>
            </>) : null}

            {otherPics.length > 0 && (
                <>
                    <Typography level="h4">Other Photos</Typography>
                    <ActivityPhotos pictures={otherPics}/>
                </>
            )}

            {activity.location?.id && (<>
                <Typography level="h4">Location</Typography>
                <Stack>
                    <Stack direction="row" alignItems="end">
                        <Typography level="body-lg" whiteSpace="pre-line">{activity.location?.address}</Typography>
                        <Box flexGrow={1} display="flex" flexDirection="row" justifyContent="right">
                            <Button onClick={() => CommonUtils.openDirections(activity.location?.address)}
                                variant="plain" color="primary">
                                <DirectionsIcon/> Directions
                            </Button>
                        </Box>
                    </Stack>
                    <div ref={mapRef} style={{height: '500px', width: '100%'}}></div>
                </Stack>
            </>)}

            <ActivityPhotoPrompt
                open={photoPromptType != null}
                onClose={closePhotoPrompt}
                onAddPhotos={addPhotos}
                photoType={photoPromptType}
                location={location}
            />
        </Stack>
    );
};

ActivityDetails.propTypes = {
    activity: PropTypes.instanceOf(Activity),
    pictures: PropTypes.instanceOf(ActivityPictureArray),
};

export default ActivityDetails;
