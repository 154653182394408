import BasicDomain from "./BasicDomain";

export default class EventLocation extends BasicDomain {
    static DEFAULTS = {
        event: null,
        location: null,
    }

    constructor(props) {
        super('EventLocation', props, EventLocation.DEFAULTS);
    }
}

global.Parse.Object.registerSubclass('EventLocation', EventLocation);
