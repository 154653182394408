import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import {Stack, Textarea, FormControl, FormLabel, Select, Option, Input} from '@mui/joy';

import { Activity, Employee } from 'domain';
import RequiredIndicator from "components/RequiredIndicator";
import CommonUtils from "utils/CommonUtils";

const ActivityForm = ({ onSave, onChange, activity = {} }) => {
    const locations = useSelector(({ locations }) => locations.list);
    const employees = useSelector(({ employees }) => employees.list);
    const profile = useSelector(({ employees }) => employees.me);
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const serviceOptionIds = activity.location?.services ?? Object.keys(serviceOptions ?? {});
    const isAdmin = profile.type === Employee.TYPE_ADMIN;

    const handleSelectLocation = (e, val) => {
        onChange('location')(null, locations.get(val))
    }

    const handleSelectEmployee = (e, val) => {
        onChange('employee')(null, employees.get(val))
    }

    const handleDateTimeChange = (key) => (e) => {
        onChange(key)(null, CommonUtils.toJSDate(e.target.value));
    }

    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
                <FormLabel>Location<RequiredIndicator /></FormLabel>
                <Select
                    size="lg"
                    variant="outlined"
                    value={activity.location?.id || ''}
                    onChange={handleSelectLocation}
                >
                    {locations.map((loc) => (
                        <Option key={loc.id} value={loc.id}>{loc.name}</Option>
                    ))}
                </Select>
            </FormControl>

            {isAdmin && (
                <FormControl>
                    <FormLabel>Employee<RequiredIndicator /></FormLabel>
                    <Select
                        size="lg"
                        variant="outlined"
                        value={activity.employee?.id || ''}
                        onChange={handleSelectEmployee}
                    >
                        {employees.map((employee) => (
                            <Option key={employee.id} value={employee.id}>{employee.name}</Option>
                        ))}
                    </Select>
                </FormControl>
            )}

            <FormControl>
                <FormLabel>Services</FormLabel>
                <Select
                    size="lg"
                    variant="outlined"
                    placeholder="No Service Needed"
                    value={activity.services || []}
                    onChange={onChange('services')}
                    multiple
                    disabled={!activity.location}
                >
                    {serviceOptionIds.map((serviceId) => (
                        <Option key={serviceId} value={serviceId}>{serviceOptions[serviceId]}</Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                    color="neutral"
                    placeholder="Notes"
                    size="lg"
                    variant="outlined"
                    value={activity.notes}
                    onChange={onChange('notes')}
                    minRows={3}
                    maxRows={8}
                />
            </FormControl>

            {isAdmin && activity.checkin != null && (
                <FormControl>
                    <FormLabel>Checkin Time</FormLabel>
                    <Input
                        type="datetime-local"
                        size="lg"
                        variant="outlined"
                        value={CommonUtils.toInputDate(activity.checkin)}
                        onChange={handleDateTimeChange('checkin')}
                    />
                </FormControl>
            )}

            {isAdmin && activity.checkout != null && (
                <FormControl>
                    <FormLabel>Checkout Time</FormLabel>
                    <Input
                        type="datetime-local"
                        size="lg"
                        variant="outlined"
                        value={CommonUtils.toInputDate(activity.checkout)}
                        onChange={handleDateTimeChange('checkout')}
                    />
                </FormControl>
            )}
        </Stack>
    );
};

ActivityForm.propTypes = {
    activity: PropTypes.instanceOf(Activity),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default ActivityForm;
