import React, { useState, useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import {
    IconButton,
    Menu,
    MenuItem,
    Button,
    Modal,
    ModalDialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@mui/joy';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import OpenIcon from "@mui/icons-material/Visibility";

import {Employee, Message} from 'domain';
import CommonUtils from "utils/CommonUtils";
import employeeActions from 'actions/employeeActions';
import appActions from "actions/appActions";
import ColorSchemeMenuItem from 'components/ColorSchemeMenuItem';

import EmployeeFormDialog from './EmployeeFormDialog';

const EmployeeActionMenu = ({ employee }) => {
    const dispatch = useDispatch();
    const { employeeId } = useParams();
    const me = useSelector(({ employees }) => employees.me);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
    const navigate = useNavigate();
    const fileInputRef = useRef();

    const isViewingEmployee = employeeId === employee.id;
    const isMe = me && me.id === employee.id;

    const handleResetPassword = () => {
        setIsResetPasswordDialogOpen(true);
        handleMenuClose();
    };

    const handleConfirmResetPassword = async () => {
        setIsResetPasswordDialogOpen(false);
        CommonUtils.dispatcher(
            employeeActions.resetPassword(employee),
            dispatch,
            'Password Reset Successfully',
            'Failed To Reset Password'
        );
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleOpen = (event) => {
        event.stopPropagation();
        navigate(`/employee/${employee.id}`);
        handleMenuClose();
    };

    const handleEdit = (event) => {
        event.stopPropagation();
        setIsDialogOpen(true);
        handleMenuClose();
    };

    const handleToggleActive = (event) => {
        event.stopPropagation();
        CommonUtils.dispatcher(
            employeeActions.save(employee.set('active', !employee.active)),
            dispatch,
            'Employee Updated',
            'Failed To Update Employee'
        );
        handleMenuClose();
    };

    const handleEditPicture = (event) => {
        event.stopPropagation();
        fileInputRef.current.click();
        handleMenuClose();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const parseFile = new Parse.File(file.name, file);
            try {
                await parseFile.save();
                employee.set('pic', parseFile);
                handleSave(employee);
            } catch (error) {
                dispatch(appActions.addNotice(new Message({ title: 'Error Uploading File', type: Message.TYPE_ERROR })))
            }
        }
    };

    const handleSave = (updatedEmployee) => {
        CommonUtils.dispatcher(
            employeeActions.save(updatedEmployee),
            dispatch,
            'Employee Saved',
            'Failed To Save Employee'
        );
        setIsDialogOpen(false);
    };

    const subscribeUser = async () => {
        if ('serviceWorker' in navigator) {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BFOgn5-PYsS-HOrObLM-zZT01Pdnq_eYo93FgjcTCqq549dJXG_zff0AQs2-WPcZWLDNzsYiy6jto4RYq1y2G7Y'
            });
            return subscription;
        }
    };

    const handleEnableNotifications = () => {
        if ('Notification' in window) {
            Notification.requestPermission().then(async permission => {
                if (permission === 'granted') {
                    const subscription = await subscribeUser();
                    if (subscription) {
                        CommonUtils.dispatcher(
                            employeeActions.save(employee
                                .set('pushNoticeSubscription', subscription.toJSON())
                                .set('enabledPushNotices', true)
                            ),
                            dispatch,
                            'Push Notifications Enabled',
                            'Failed To Enable Push Notices'
                        );
                    } else {
                        dispat(appActions.addNotice({ title: 'Failed To Subscribe', type: Message.TYPE_ERROR }))
                    }
                } else {
                    dispat(appActions.addNotice({ title: 'Failed To Enable Push Notices', type: Message.TYPE_ERROR }))
                }
            });
        } else {
            dispat(appActions.addNotice({ title: 'Push notifications are not supported in this browser.', type: Message.TYPE_ERROR }))
        }
    };

    const handleDisableNotifications = () => {
        CommonUtils.dispatcher(
            employeeActions.save(employee.set('enabledPushNotices', false)),
            dispatch,
            'Push Notifications Disabled',
            'Failed To Disable Push Notices'
        );
    }

    if (!me || (me.type !== Employee.TYPE_ADMIN && !isMe)) {
        return null;
    }

    return (

        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <ClickAwayListener onClickAway={handleMenuClose}>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {!isViewingEmployee && <MenuItem onClick={handleOpen}>
                        <OpenIcon/> View
                    </MenuItem>}
                    <MenuItem onClick={handleEdit}>
                        <EditIcon /> Edit
                    </MenuItem>
                    <MenuItem onClick={handleEditPicture}>
                        <PhotoCameraIcon /> Change Picture
                    </MenuItem>
                    {isMe && !employee.enabledPushNotices && 'Notification' in window && (
                        <MenuItem onClick={handleEnableNotifications} disabled>
                            <NotificationsIcon/> Enable Notifications
                        </MenuItem>
                    )}
                    {isMe && employee.enabledPushNotices && (
                        <MenuItem onClick={handleDisableNotifications} disabled>
                            <NotificationsOffIcon/> Disable Notifications
                        </MenuItem>
                    )}
                    {isMe && (
                        <ColorSchemeMenuItem />
                    )}
                    {!isMe && (
                        <MenuItem onClick={handleResetPassword}>
                            <VpnKeyIcon/> Reset Password
                        </MenuItem>
                    )}
                    {!isMe && (
                        <MenuItem onClick={handleToggleActive}>
                            {employee.active ? <ToggleOffIcon /> : <ToggleOnIcon />}
                            {employee.active ? 'Deactivate' : 'Activate'}
                        </MenuItem>
                    )}
                </Menu>
            </ClickAwayListener>
            <EmployeeFormDialog
                key={employee.id}
                employee={employee}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
            />
            <input
                type="file"
                ref={fileInputRef}
                hidden
                onChange={handleFileChange}
                accept="image/*"
            />
            <Modal
                open={isResetPasswordDialogOpen}
                onClose={() => setIsResetPasswordDialogOpen(false)}
            >
                <ModalDialog>
                    <DialogTitle>
                        Reset Password?
                    </DialogTitle>
                    <DialogContent>
                    This will change the employee&apos;s password and email them a new autogenerated password.
                    The employee&apos;s old password will no longer work. Are you sure you want to do this?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" onClick={handleConfirmResetPassword}>Confirm</Button>
                        <Button variant="plain" onClick={() => setIsResetPasswordDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    );
};

EmployeeActionMenu.propTypes = {
    employee: PropTypes.instanceOf(Employee).isRequired,
};

export default EmployeeActionMenu;
