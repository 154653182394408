import React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, Checkbox} from '@mui/joy';

import {ActivityArray, EventLocationArray} from "domain";
import CommonUtils from "utils/CommonUtils";
import EventLocationTableRow from "components/event/EventLocationTableRow";

const EventLocationTable = ({ eventLocations, activities, selected, onSelect }) => {
    const isAllSelected = selected.length === eventLocations.length;
    const isSomeSelected = selected.length > 0 && selected.length < eventLocations.length;

    const handleSelect = (dl) => {
        if(selected.some(item => item.id === dl.id)) {
            onSelect(new EventLocationArray(selected.remove(dl)));
        } else {
            onSelect(new EventLocationArray(selected.add(dl)));
        }
    };

    const handleSelectAll = () => {
        if (isAllSelected) {
            onSelect(new EventLocationArray());
        } else {
            onSelect(new EventLocationArray(eventLocations));
        }
    };

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table stickyHeader>
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={isSomeSelected}
                                onClick={handleSelectAll}
                            />
                        </th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Assigned</th>
                        <th>Activity</th>
                        <th>Last Serviced</th>
                    </tr>
                </thead>
                <tbody>
                    {eventLocations.map((dl) => (
                        <EventLocationTableRow
                            key={dl.id}
                            selected={selected.some(item => item.id === dl.id)}
                            eventLocation={dl}
                            activities={activities.filter(activity => activity.location.id === dl.location.id)}
                            onSelect={handleSelect}
                        />
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

EventLocationTable.propTypes = {
    eventLocations: PropTypes.instanceOf(EventLocationArray).isRequired,
    activities: PropTypes.instanceOf(ActivityArray),
    selected: PropTypes.instanceOf(EventLocationArray),
    onSelect: PropTypes.func,
};

EventLocationTable.defaultProps = {
    selected: new EventLocationArray(),
    activities: new ActivityArray(),
    onSelect: CommonUtils.EMPTY_FUNCTION,
};

export default EventLocationTable;
