import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Config from 'Config';
import { Box, Skeleton} from "@mui/joy";

import NoLocationsMessage from "components/location/NoLocationsMessage";

const { googleApiKey } = Config;

const LocationMap = ({ height, width }) => {
    const locations = useSelector(({ locations }) => locations.list);
    const isLoading = useSelector(({ locations }) => locations.isLoading);

    if (isLoading) {
        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', borderRadius: 'md' }}>
            <Skeleton variant="rectangular" height={height} width={width} />
        </Box>
    }

    const markers = locations
        .filter(location => (location.point && location.point.latitude !== 0 && location.point.longitude !== 0))
        .map(location => {
            if (!location.point) {
                return '';
            }

            const { latitude, longitude } = location.point;
            return `markers=color:0xf59e0b%7C${latitude},${longitude}`;
        })
        .join('&');

    if (markers.length === 0) {
        return <NoLocationsMessage />
    }

    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=${width}x${height}&${markers}&key=${googleApiKey}`;

    return (
        <Box sx={{ width: '100%', height: '100%', maxHeight: 600, overflow: 'hidden', aspectRatio: 1, borderRadius: 'md' }}>
            <img src={mapUrl} alt="Map of Locations" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
    );
};

LocationMap.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

LocationMap.defaultProps = {
    height: 600,
    width: 600,
};

export default LocationMap;
