import BasicDomain from "./BasicDomain";

export default class Location extends BasicDomain {

    static DEFAULTS = {
        name: '',
        address: '',
        services: [],
        account: null,
        point: null,
        active: true,
        isPhotosRequired: false,
        minBeforePhotos: 1,
        minAfterPhotos: 1,
    }

    constructor(props) {
        super('Location', props, Location.DEFAULTS);
    }

    isSavable = () => (
        this.name != null &&
        this.name.trim() !== '' &&
        this.address != null &&
        this.address.trim() !== '' &&
        (!this.isPhotosRequired || (
            this.isPhotosRequired &&
            typeof this.minBeforePhotos === 'number' &&
            typeof this.minAfterPhotos === 'number' &&
            this.minBeforePhotos + this.minAfterPhotos > 1
        ))
    );


    getPhotoRequirementText = () => {
        if (!this.isPhotosRequired) return null;

        let text = [];

        if (this.minBeforePhotos > 0) {
            text.push(`${this.minBeforePhotos} Before Photo${this.minBeforePhotos > 1 ? 's' : ''}`);
        }

        if (this.minAfterPhotos > 0) {
            text.push(`${this.minAfterPhotos} After Photo${this.minAfterPhotos > 1 ? 's' : ''}`);
        }

        return text.join(' & ')
    };
}

global.Parse.Object.registerSubclass('Location', Location);
