import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "@mui/joy";

import {Event} from "domain";
import CommonUtils from "utils/CommonUtils";

const OpenActiveButton = ({ children, activeEvent, disabled, fullWidth, onClick, size }) => activeEvent && (
    <Button
        fullWidth={fullWidth}
        variant="soft"
        color="success"
        disabled={disabled}
        onClick={onClick}
        size={size}
        sx={{
            py: size === 'sm' ? null : 2,
            display: 'flex',
            gap: 1,
            animation: disabled ? 'none' : 'glow 2s infinite',
            '@keyframes glow': {
                '0%': {
                    backgroundColor: 'rgba(0, 123, 85, 1)',
                    opacity: 1,
                },
                '50%': {
                    backgroundColor: 'rgba(0, 123, 85, 0.7)',
                    opacity: 0.7,
                },
                '100%': {
                    backgroundColor: 'rgba(0, 123, 85, 1)',
                    opacity: 1,
                },
            }
        }}
    >
        {children}
    </Button>
);

OpenActiveButton.propTypes = {
    children: PropTypes.node,
    activeEvent: PropTypes.instanceOf(Event),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
    onClick: PropTypes.func,
};

OpenActiveButton.defaultProps = {
    activeEvent: null,
    disabled: false,
    fullWidth: false,
    size: 'md',
    onClick: CommonUtils.EMPTY_FUNCTION,
};

export default OpenActiveButton;
