import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import { Box, Table, Chip, Checkbox } from '@mui/joy';

import CommonUtils from "utils/CommonUtils";
import {LocationArray} from "domain";

import {useNavigate} from "react-router-dom";

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
};

const LocationTable = ({ locations, selected, onSelect }) => {
    const navigate = useNavigate();
    const serviceOptions = useSelector(({ account }) => account.lov.services);
    const isAllSelected = selected.length === locations.length;
    const isSomeSelected = selected.length > 0 && selected.length < locations.length;

    const handleOpen = (location) => () => {
        navigate(`/location/${location.id}`);
    };

    const handleSelect = (locationId) => (event) => {
        event.stopPropagation();
        if (selected.includes(locationId)) {
            onSelect(selected.filter(id => id !== locationId)); // Deselect
        } else {
            onSelect([...selected, locationId]); // Select
        }
    };

    const handleSelectAll = () => {
        if (isAllSelected) {
            onSelect([]); // Deselect all
        } else {
            onSelect(locations.map(loc => loc.id)); // Select all
        }
    };

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table stickyHeader hoverRow>
                <thead>
                    <tr>
                        <th style={{ width: 50 }}>
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={isSomeSelected}
                                onClick={handleSelectAll}
                            />
                        </th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Services</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    {locations.map((location, ix) => (
                        <tr key={location.id ?? ix} onClick={handleOpen(location)} style={{ cursor: 'pointer' }}>
                            <td>
                                <Checkbox
                                    checked={selected.includes(location.id)}
                                    onClick={handleSelect(location.id)}
                                />
                            </td>
                            <td style={styles.cell}>{location.name}</td>
                            <td style={styles.cell}>{location.address}</td>
                            <td style={styles.cell}>
                                {location.services.map((serviceId) => {
                                    return (<Chip key={serviceId} color="primary" sx={{mr: 1}}>{serviceOptions ? serviceOptions[serviceId] : serviceId}</Chip>)
                                })}
                            </td>
                            <td>{CommonUtils.formatDate(location.createdAt)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

LocationTable.propTypes = {
    locations: PropTypes.instanceOf(LocationArray).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func,
};

LocationTable.defaultProps = {
    selected: [],
    onSelect: CommonUtils.EMPTY_FUNCTION,
};

export default LocationTable;
