import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import {Box, Button, FormControl, FormLabel, Textarea} from "@mui/joy";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";

import './ActivityAnimations.css';
import CommonUtils from "utils/CommonUtils";
import activityActions from "actions/activityActions";
import {Activity} from "domain";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const ActivityQuickForm = ({ activity, onUpdate, enableCheckout }) => {
    const dispatch = useDispatch();
    const [myNote, setNote] = useState(activity.notes)
    const [isProcessing, setProcessing] = useState(false)
    let btn = null;

    const updateActivity = (updatedActivity, mode = "other") => {
        setProcessing(true);
        CommonUtils.dispatcher(
            activityActions.save(updatedActivity),
            dispatch,
            'Activity Updated',
            'Failed To Update Activity'
        ).then(() => {
            setProcessing(false);
            onUpdate(mode)
        });
    };

    const _handleCheckInWithGeo = (geoPoint) => {
        const updatedActivity = new Activity(activity
            .set('checkin', new Date())
            .set('status', Activity.STATUS_IN_PROGRESS)
            .set('checkinGeo', geoPoint)
        );
        updateActivity(updatedActivity, "checkin");
    };

    const handleCheckIn = () => {
        setProcessing(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    _handleCheckInWithGeo({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                () => {
                    _handleCheckInWithGeo(null);
                },
                { enableHighAccuracy: true }
            );
        } else {
            _handleCheckInWithGeo(null);
        }
    };


    const _handleCheckOutWithGeo = (geoPoint) => {
        const updatedActivity = new Activity(activity
            .set('checkout', new Date())
            .set('status', Activity.STATUS_COMPLETE)
            .set('checkoutGeo', geoPoint)
        );
        updateActivity(updatedActivity, "checkout");
    };

    const handleCheckOut = () => {
        setProcessing(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    _handleCheckOutWithGeo({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                () => {
                    _handleCheckOutWithGeo(null);
                },
                { enableHighAccuracy: true }
            );
        } else {
            _handleCheckOutWithGeo(null);
        }
    };

    const handleChangeNote = (e) => {
        setNote(e?.target?.value);
    }

    const handleSaveNote = () => {
        updateActivity(new Activity(activity.set('notes', myNote)));
    }

    if (activity.checkin == null) {
        btn = (
            <Box align="center">
                <Button
                    startDecorator={<CheckIcon />}
                    onClick={handleCheckIn}
                    size="lg"
                    color="success"
                    loading={isProcessing}
                    loadingPosition="start"
                    sx={{animation: 'pulse-success 1.5s infinite'}}
                >
                    {isProcessing ? 'Updating...' : 'Check In'}
                </Button>
            </Box>
        )
    } else if (activity.checkout == null) {
        btn = (
            <Box align="center">
                <Button
                    startDecorator={<ExitToAppIcon />}
                    onClick={handleCheckOut}
                    size="lg"
                    color="warning"
                    variant="solid"
                    loading={isProcessing}
                    loadingPosition="start"
                    disabled={!enableCheckout}
                    sx={{animation: enableCheckout ? 'pulse-warning 1.5s infinite' : null}}
                >
                    {isProcessing ? 'Updating...' : 'Check Out'}
                </Button>
            </Box>
        )
    }

    return (
        <>
            {btn}
            <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                    color="neutral"
                    placeholder="Notes"
                    size="lg"
                    variant="outlined"
                    onChange={handleChangeNote}
                    value={myNote}
                    minRows={3}
                    maxRows={8}
                />
            </FormControl>
            {activity.notes !== myNote && (
                <Box align="right">
                    <Button
                        onClick={handleSaveNote}
                        startDecorator={<SaveIcon />}
                        loading={isProcessing}
                    >Save Note</Button>
                </Box>
            )}
        </>
    )
}

ActivityQuickForm.propTypes = {
    activity: PropTypes.instanceOf(Activity),
    onUpdate: PropTypes.func.isRequired,
    enableCheckout: PropTypes.bool,
};

ActivityQuickForm.defaultProps = {
    enableCheckout: true,
}

export default ActivityQuickForm;
