import React from 'react';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/joy';

import './EventMeter.css';

const HEIGHT = 32;

const MeterRoot = styled(Box)(({ theme, active }) => ({
    display: 'block',
    height: HEIGHT,
    position: 'relative',
    background: theme.vars.palette.background.level1,
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    padding: 0,
    boxSizing: 'content-box',
    boxShadow: 'inset 0 -1px 5px rgba(104, 104, 104, 0.4)',
    whiteSpace: 'nowrap',
    '& div': {
        animation: active === 'true' ? 'move 2s linear infinite' : 'none',
    }
}));

const MeterBar = styled(Box)(({ theme, color }) => {
    return ({
        display: 'inline-block',
        position: 'absolute',
        height: HEIGHT,
        boxShadow: 'inset 0 2px 9px  rgba(255,255,255,0.4), inset 0 -2px 6px rgba(0,0,0,0.5)',
        backgroundColor: color === 'warning' ?  theme.palette[color].solidBg : theme.palette[color].softBg,
        backgroundImage: 'linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)',
        backgroundSize: '50px 50px',
        overflow: 'hidden',
    })
});

const EventProgressBar = ({ completed, inProgress, pending, active, disabled }) => {
    const total = completed + inProgress + pending;
    const pctCompleted = Math.max(Math.min((completed / total) * 100, 100), 0);
    const pctInProgress = Math.max(Math.min(((inProgress + completed) / total) * 100, 100), 0);

    if (disabled) {
        return <MeterRoot sx={{ background: 'grey.300' }} />;
    }

    return (
        <MeterRoot active={active.toString()}>
            <MeterBar color="success" style={{ width: `${pctCompleted}%`, zIndex: 3 }} />
            <MeterBar color="warning" style={{ width: `${pctInProgress}%`, zIndex: 2 }} />
            <MeterBar color="danger" style={{ width: '100%', zIndex: 1 }} />
        </MeterRoot>
    );
};

EventProgressBar.propTypes = {
    completed: PropTypes.number,
    inProgress: PropTypes.number,
    pending: PropTypes.number,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
};

EventProgressBar.defaultProps = {
    completed: 0,
    inProgress: 0,
    pending: 0,
    disabled: false,
    active: true,
};

export default EventProgressBar;
