import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import { Box, Table, Avatar } from '@mui/joy';
import PersonIcon from '@mui/icons-material/Person';

import { EmployeeArray, Employee } from "domain";
import TimeStatus from "components/TimeStatus";
import CommonUtils from "utils/CommonUtils";

const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
        maxWidth: '100%',
    },
    linkText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',

    },
    icon: {
        fontSize: '1rem',
        verticalAlign: 'middle',
    },
};

const EmployeeTable = ({ employees }) => {
    const navigate = useNavigate();

    const handleOpen = (employee) => () => {
        navigate(`/employee/${employee.id}`);
    };

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table stickyHeader hoverRow>
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>&nbsp;</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Created</th>
                        <th>Last Active</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee, index) => (
                        <tr key={index} onClick={handleOpen(employee)} style={{ cursor: 'pointer' }}>
                            <td>
                                <Avatar src={employee.pic?.url()}>
                                    <PersonIcon />
                                </Avatar>
                            </td>
                            <td style={styles.cell}>{employee.name}</td>
                            <td style={styles.cell}>{Employee.JOB_LABELS[employee.job]}</td>
                            <td style={styles.cell}>{employee.email}</td>
                            <td style={styles.cell}>{employee.phone}</td>
                            <td style={styles.cell}>{CommonUtils.formatDate(employee.createdAt)}</td>
                            <td style={styles.cell}><TimeStatus date={employee.lastActive} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Box>
    );
};

EmployeeTable.propTypes = {
    employees: PropTypes.instanceOf(EmployeeArray).isRequired,
};

export default EmployeeTable;
