import EventLocation from './EventLocation';
import BasicArray from './BasicArray';

export default class EventLocationArray extends BasicArray {
    get myItemClass() { return EventLocation; }

    constructor(items = []) {
        super(items);
    }
}
