import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControl, FormLabel, Input, Textarea, FormHelperText } from '@mui/joy';

import { Event } from 'domain';
import CommonUtils from "utils/CommonUtils";

const EventForm = ({ onSave, onChange, event, errors }) => {

    const handleDateTimeChange = (key) => (e) => {
        onChange(key)({ target: { value: CommonUtils.toJSDate(e.target.value) } });
    };

    return (
        <Stack component="form" onSubmit={onSave} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl error={!!errors.description}>
                <FormLabel>Description</FormLabel>
                <Textarea
                    placeholder="Event Description"
                    size="lg"
                    variant="outlined"
                    value={event.description || ''}
                    onChange={onChange('description')}
                    error={!!errors.description}
                />
                <FormHelperText>{errors.description}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.start} required>
                <FormLabel>Start Time</FormLabel>
                <Input
                    type="datetime-local"
                    size="lg"
                    variant="outlined"
                    value={CommonUtils.toInputDate(event.start)}
                    onChange={handleDateTimeChange('start')}
                    error={!!errors.start}
                />
                <FormHelperText>{errors.start}</FormHelperText>
            </FormControl>

            <FormControl error={!!errors.end} required>
                <FormLabel>End Time</FormLabel>
                <Input
                    type="datetime-local"
                    size="lg"
                    variant="outlined"
                    value={CommonUtils.toInputDate(event.end)}
                    onChange={handleDateTimeChange('end')}
                    error={!!errors.end}
                />
                <FormHelperText>{errors.end}</FormHelperText>
            </FormControl>
        </Stack>
    );
};

EventForm.propTypes = {
    event: PropTypes.instanceOf(Event),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    errors: PropTypes.object,
};

export default EventForm;
