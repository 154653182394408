import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Badge, Card, CardContent, CardOverflow, Chip, Typography, Stack} from "@mui/joy";
import ArrowIcon from "@mui/icons-material/DoubleArrow";

import './ActivityAnimations.css';
import {Activity} from "domain";
import ImageIcon from "@mui/icons-material/Image";

const ActivityListItem = ({ activity, onSelect, isInProgress }) => (
    <Card
        variant="outlined"
        orientation="horizontal"
        onClick={() => onSelect(activity)}
        color={isInProgress ? 'warning' : 'neutral'}
        sx={{cursor: 'pointer', animation: isInProgress ? 'pulse-warning 1.5s infinite' : null}}
    >
        <CardContent>
            <Typography level="h2" color={isInProgress ? 'warning' : 'neutral'}>{activity.location?.name || 'N/A'}</Typography>
            <Typography level="body-sm" gutterBottom>
                Checked In: {activity.checkin ? activity.checkin.toLocaleString() : 'N/A'}
            </Typography>
            {activity.checkout && <Typography level="body-sm">
                Checked Out: {activity.checkout.toLocaleString()}
            </Typography>}
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pt={1}
            >
                <Chip color={Activity.STATUS_COLORS[activity.status] ?? "neutral"} variant="soft">
                    {Activity.STATUS_LABELS[activity.status] ?? 'N/A'}
                </Chip>
                {activity.photoCount > 0 ? (
                    <Badge badgeContent={activity.photoCount} size="sm" color="primary">
                        <ImageIcon/>
                    </Badge>
                ) : null}
            </Stack>
        </CardContent>
        <CardOverflow
            variant="soft"
            color={isInProgress ? 'warning' : 'neutral'}
            sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
        >
            <Avatar
                color={isInProgress ? 'warning' : 'neutral'}
            >
                <ArrowIcon/>
            </Avatar>
        </CardOverflow>
    </Card>
);

ActivityListItem.propTypes = {
    activity: PropTypes.instanceOf(Activity).isRequired,
    onSelect: PropTypes.func.isRequired,
    isInProgress: PropTypes.bool,
};

ActivityListItem.defaultProps = {
    isInProgress: false,
};

export default ActivityListItem;
