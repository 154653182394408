import Activity from "./Activity";

export default class ActivityCriteria {
    static SORT_DIR_ASC = 'A';

    static SORT_DIR_DESC = 'D';

    static SORT_DIRECTIONS = [
        ActivityCriteria.SORT_DIR_ASC,
        ActivityCriteria.SORT_DIR_DESC,
    ];

    static SORT_FIELDS = [...Object.keys(Activity.DEFAULTS)];

    static DEFAULTS = {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        endDate: null,
        locations: [],
        employees: [],
        sortField: 'checkin',
        sortDir: ActivityCriteria.SORT_DIR_DESC,
        saveToStore: false
    }

    constructor(props = {}) {
        Object.keys(ActivityCriteria.DEFAULTS).forEach(key => {
            this[key] = props[key] ?? ActivityCriteria.DEFAULTS[key];
        });
    }

    get valid() {
        return (
            this.startDate != null
            && ActivityCriteria.SORT_FIELDS.includes(this.sortField)
            && ActivityCriteria.SORT_DIRECTIONS.includes(this.sortDir)
        );
    }
}
