import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Box, Button, Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, DialogActions, Typography, useTheme} from '@mui/joy';
import {useMediaQuery} from "@mui/material";
import DownloadIcon from "@mui/icons-material/FileDownload";
import CommonUtils from "utils/CommonUtils";
import {ActivityPicture} from "domain";

const ActivityPhotos = ({ pictures }) => {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(null);

    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedImage(null);
        setOpen(false)
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(selectedImage.image.url());
            if (!response.ok) throw new Error('Network response was not ok.');
            const imageBlob = await response.blob();
            const imageObjectURL = URL.createObjectURL(imageBlob);
            const link = document.createElement('a');
            link.href = imageObjectURL;

            const fileName = selectedImage.image.url().split('/').pop() || `Downloaded_Image_${new Date().getTime()}`;
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(imageObjectURL);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    }

    const modalContent = () => {
        if (!selectedImage) return null;
        return (
            <>
                <DialogContent>
                    <img src={selectedImage.image.url()} alt="Activity Photo" style={{ maxWidth: '100%', width: 'fit-content', margin: 'auto' }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" startDecorator={<DownloadIcon />} onClick={handleDownload}>
                            Download
                    </Button>
                    <Button variant="plain" color="neutral" onClick={handleClose}>
                            Close
                    </Button>
                    <div style={{ flex: '1' }}>
                        {selectedImage? (
                            <Typography level="body-xs" color="neutral">Uploaded on: {CommonUtils.formatDateTime(selectedImage.createdAt)}</Typography>
                        ) : (null)}
                    </div>
                </DialogActions>
            </>
        )
    }

    return (
        <Box display="flex" gap={2} flexWrap="wrap" component="div">
            {pictures.length === 0 && (
                <Typography level="body-xs" color="neutral">Add photos to this activity</Typography>
            )}
            {pictures.map((pic, index) => (
                <Avatar
                    key={index}
                    src={pic.thumbnail?.url() ?? pic.image.url()}
                    alt={`Photo ${index + 1}`}
                    onClick={() => handleOpen(pic)}
                    style={{ cursor: 'pointer' }}
                />
            ))}
            <Modal open={open} onClose={handleClose}>
                <ModalDialog layout={isXsScreen ? "fullscreen" : "center"} variant="soft" sx={{ minWidth: '80%' }}>
                    <ModalClose />
                    <DialogTitle>Activity Photo</DialogTitle>
                    {modalContent()}
                </ModalDialog>
            </Modal>
        </Box>
    );
};

ActivityPhotos.propTypes = {
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(ActivityPicture)).isRequired,
};

export default ActivityPhotos;
