import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import { Box, Table, Chip, Skeleton, Badge, Typography, IconButton } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';
import ImageIcon from '@mui/icons-material/Image';

import CommonUtils from "utils/CommonUtils";
import ItemLoader from "components/ItemLoader";
import { Activity, ActivityCriteria } from 'domain';


const styles = {
    cell: {
        maxWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    sortIcon: {
        height: 20,
        verticalAlign: 'middle'
    },
    sortHeader: {
        cursor: 'pointer',
        userSelect: 'none'
    },
    nonSortHeader: {
        userSelect: 'none'
    }
};

const SkeletonRow = () => (
    <>
        <td><Skeleton width={120} height={18} sx={{position: 'relative'}}/></td>
        <td><Skeleton width={120} height={18} sx={{position: 'relative'}}/></td>
        <td><Skeleton width={80} height={18} sx={{position: 'relative'}}/></td>
        <td><Skeleton width={80} height={18} sx={{position: 'relative'}}/></td>
        <td>
            <Skeleton width={80} height={18} sx={{borderRadius: 12, position: 'relative'}}/>
        </td>
        <td>
            <Skeleton width={80} height={18} sx={{borderRadius: 12, position: 'relative'}}/>
        </td>
        <td>&nbsp;</td>
    </>
)

const ActivityTable = ({
    activities,
    loading,
    dense,
    onMore,
    isMore,
    sortDir,
    sortField,
    handleChangeSort,
    hideFeilds
}) => {
    const navigate = useNavigate();
    const serviceOptions = useSelector(({account}) => account.lov.services);

    const handleOpen = (activity) => () => {
        navigate(`/activity/${activity.id}`);
    };

    const generatePhotoIcon = (activity) => {
        return activity.photoCount > 0 ? (
            <Badge badgeContent={activity.photoCount} size="sm" color="primary">
                <ImageIcon/>
            </Badge>
        ) : null
    }

    const fields = [
        {
            id: 'employee',
            label: 'Employee',
            sort: false
        },
        {
            id: 'location',
            label: 'Location',
            sort: false
        },
        {
            id: 'checkin',
            label: 'Check-in',
            sort: true
        },
        {
            id: 'checkout',
            label: 'Check-out',
            sort: true
        },
        {
            id: 'status',
            label: 'Status',
            sort: true
        },
        {
            id: 'service',
            label: 'Services',
            sort: false
        },
    ]
    const handleSort = (field) => {
        if (field.sort) {
            if (field.id === sortField) {
                handleChangeSort({
                    sortField: field.id,
                    sortDir: sortDir === 'A' ? 'D' : 'A'
                })
            } else {
                handleChangeSort({
                    sortField: field.id,
                    sortDir: 'D'
                })
            }
        }
    }

    const headers = fields
        .filter(f => !hideFeilds.includes(f.id))
        .map((field) => {
            return (
                <th 
                    key={field.id}
                    style={field.sort ? styles.sortHeader : styles.nonSortHeader}
                    onClick={() => {handleSort(field)}}
                >
                    <span>{field.label}</span>
                    {field.id === sortField && (
                        sortDir === 'A' ? 
                            <ExpandLessIcon style={styles.sortIcon}/> : 
                            <ExpandMoreIcon style={styles.sortIcon}/>
                    )}
                </th>
            )
        })

    if (dense) {
        return (
            <Table stickyHeader hoverRow>
                <thead>
                    <tr>
                        <th>Employee</th>
                        <th>Location</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map((activity, ix) => (
                        <tr key={activity.id ?? ix} onClick={handleOpen(activity)} style={{ cursor: 'pointer' }}>
                            <td style={styles.cell}>{activity.employee?.name ?? 'N/A'}</td>
                            <td style={styles.cell}>{activity.location?.name ?? 'N/A'}</td>
                            <td>
                                <Chip color={Activity.STATUS_COLORS[activity.status]}>
                                    {Activity.STATUS_LABELS[activity.status]}
                                </Chip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    return (
        <Box sx={{ maxWidth: '100%' }}>
            <Table stickyHeader hoverRow>
                <thead>
                    <tr>
                        {headers}
                        {!hideFeilds.includes('photos') && (
                            <th style={{ width: 42 }}>&nbsp;</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {loading && Array(5).fill(0).map((v, ix) => (
                        <tr key={ix}>
                            <SkeletonRow />
                        </tr>
                    ))}
                    {activities.map((activity, ix) => (
                        <tr key={activity.id ?? ix} onClick={handleOpen(activity)} style={{ cursor: 'pointer' }}>
                            {!hideFeilds.includes('employee') && (
                                <td style={styles.cell}>{activity.employee?.name ?? 'N/A'}</td>
                            )}
                            {!hideFeilds.includes('location') && (
                                <td style={styles.cell}>{activity.location?.name ?? 'N/A'}</td>
                            )}
                            {!hideFeilds.includes('checkin') && (
                                <td>{CommonUtils.formatDateTime(activity.checkin)}</td>
                            )}
                            {!hideFeilds.includes('checkout') && (
                                <td>{CommonUtils.formatDateTime(activity.checkout)}</td>
                            )}
                            {!hideFeilds.includes('status') && (
                                <td>
                                    <Chip color={Activity.STATUS_COLORS[activity.status]}>
                                        {Activity.STATUS_LABELS[activity.status]}
                                    </Chip>
                                </td>
                            )}
                            {!hideFeilds.includes('service') && (
                                <td style={styles.cell}>
                                    {activity.services.map((serviceId) => {
                                        return (<Chip key={serviceId} color="neutral" sx={{mr: 1}}>{serviceOptions ? serviceOptions[serviceId] : serviceId}</Chip>)
                                    })}
                                    {activity.services.length === 0 && (
                                        <Typography level="body-sm" color="neutral" sx={{fontStyle: 'italic'}}>No Service Needed</Typography>
                                    )}
                                </td>
                            )}
                            {!hideFeilds.includes('photos') && (
                                <td>
                                    {generatePhotoIcon(activity)}
                                </td>
                            )}
                        </tr>
                    ))}
                    <ItemLoader onMore={onMore} enabled={isMore} component="tr">
                        <SkeletonRow />
                    </ItemLoader>
                </tbody>
            </Table>
        </Box>
    );
};

ActivityTable.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.instanceOf(Activity)).isRequired,
    loading: PropTypes.bool,
    dense: PropTypes.bool,
    onMore: PropTypes.func,
    isMore: PropTypes.bool,
    sortDir: PropTypes.oneOf(ActivityCriteria.SORT_DIRECTIONS),
    sortField: PropTypes.oneOf(ActivityCriteria.SORT_FIELDS),
    handleChangeSort: PropTypes.func,
    hideFeilds: PropTypes.arrayOf(PropTypes.string)
};

ActivityTable.defaultProps = {
    loading: false,
    dense: false,
    onMore: () => {},
    isMore: false,
    sortDir: ActivityCriteria.SORT_DIR_DESC,
    sortField: ActivityCriteria.DEFAULTS.sortField,
    handleChangeSort: () => {},
    hideFeilds: []
};

export default ActivityTable;
