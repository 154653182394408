import Event from './Event';
import BasicArray from './BasicArray';

export default class EventArray extends BasicArray {
    get myItemClass() { return Event; }

    constructor(items = []) {
        super(items);
    }
}
