import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { Sheet, Grid, Select, Option, FormLabel, FormControl, Input } from '@mui/joy';

const ActivityFilters = ({ 
    onChange,
    currentStartDate,
    currentEndDate,
    currentLocations,
    currentEmployees,
    hideFeilds
}) => {
    const [searchParams] = useSearchParams();
    const startParam = searchParams.get('start');
    const endParam = searchParams.get('end');
    const locationsParam = searchParams.get('locations');
    const employeesParam = searchParams.get('employees');

    const defaultStartDate = startParam || currentStartDate || ''
    const defaultEndDate = endParam || currentEndDate || ''
    const defaultLocations = locationsParam ? locationsParam.split(',') : ( currentLocations || [] )
    const defaultEmployees = employeesParam ? employeesParam.split(',') : ( currentEmployees || [] )
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [selectedLocations, setSelectedLocations] = useState(defaultLocations);
    const [selectedEmployees, setSelectedEmployees] = useState(defaultEmployees);
    const locations = useSelector(state => state.locations.list);
    const employees = useSelector(state => state.employees.list);

    useEffect(() => {
        const start = startDate ? new Date(startDate) : new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        const end = endDate ? new Date(endDate) : undefined;
        onChange({ startDate: start, endDate: end, locations: selectedLocations, employees: selectedEmployees});
    }, [startDate, endDate, selectedLocations, selectedEmployees]);

    const handleStartDateChange = (event) => {
        const newStartDate = event.target.value;
        if (!endDate || newStartDate <= endDate) {
            setStartDate(newStartDate);
        } else {
            setStartDate(endDate);
        }
    };

    const handleEndDateChange = (event) => {
        const newEndDate = event.target.value;
        if (!startDate || newEndDate >= startDate) {
            setEndDate(newEndDate);
        } else {
            setEndDate(startDate);
        }
    };

    const handleLocationsChange = (e, value) => setSelectedLocations(typeof value === 'string' ? value.split(',') : value);
    const handleEmployeesChange = (e, value) => setSelectedEmployees(typeof value === 'string' ? value.split(',') : value);

    const formatDate = (date) => {
        return date ? new Date(date).toISOString().slice(0, 16) : '';
    };

    const numberOfFields = 4 - hideFeilds.length
    const smWidth = 12 / numberOfFields
    
    return (
        <Sheet variant="soft" sx={{ p: 2 }}>
            <Grid container spacing={2}>

                {!hideFeilds.includes('startDate') && (
                    <Grid xs={12} sm={smWidth}>
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input
                                placeholder="Start date & time"
                                type="datetime-local"
                                value={startDate}
                                onChange={handleStartDateChange}
                                slotProps={{
                                    input: {
                                        max: formatDate(endDate !== '' ? endDate : new Date()),
                                    },
                                }}
                                size="lg"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                )}

                {!hideFeilds.includes('endDate') && (
                    <Grid xs={12} sm={smWidth}>
                        <FormControl>
                            <FormLabel>End Date</FormLabel>
                            <Input
                                key={`ed-${startDate}`}
                                placeholder="End date & time"
                                type="datetime-local"
                                value={endDate}
                                onChange={handleEndDateChange}
                                slotProps={{
                                    input: {
                                        min: startDate !== '' ? formatDate(startDate) : undefined,
                                        max: formatDate(new Date()),
                                    },
                                }}
                                size="lg"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                )}

                {!hideFeilds.includes('location') && (
                    <Grid xs={12} sm={smWidth}>
                        <FormControl>
                            <FormLabel>Locations</FormLabel>
                            <Select
                                value={selectedLocations}
                                multiple
                                onChange={handleLocationsChange}
                                placeholder="All Locations"
                                sx={{
                                    '.MuiSelect-button': {
                                        whiteSpace: 'normal',
                                        paddingY: '10px',
                                        textAlign: "left",
                                    },
                                }}
                            >
                                {locations.map((location) => (
                                    <Option key={location.id} value={location.id}>{location.name}</Option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {!hideFeilds.includes('employee') && (
                    <Grid xs={12} sm={smWidth}>
                        <FormControl>
                            <FormLabel>Employees</FormLabel>
                            <Select
                                value={selectedEmployees}
                                multiple
                                onChange={handleEmployeesChange}
                                placeholder="All Employees"
                                sx={{
                                    '.MuiSelect-button': {
                                        whiteSpace: 'normal',
                                        paddingY: '10px',
                                        textAlign: "left",
                                    },
                                }}
                            >
                                {employees.map((employee) => (
                                    <Option key={employee.id} value={employee.id}>{employee.name}</Option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

            </Grid>
        </Sheet>
    );
};

ActivityFilters.propTypes = {
    onChange: PropTypes.func.isRequired,
    hideFeilds: PropTypes.arrayOf(PropTypes.string)
}

ActivityFilters.defaultProps = {
    hideFeilds: []
}
export default ActivityFilters;
