import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";

import CommonUtils from "utils/CommonUtils";
import locationActions from "actions/locationActions";

import LocationFormDialog from "./LocationFormDialog";
import {Employee, Location} from "domain";

const AddLocationButton = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const me = useSelector(({ employees }) => employees.me);
    const dispatch = useDispatch();

    const handleSave = (myLocation) => {
        CommonUtils.dispatcher(
            locationActions.save(myLocation.set('account', me.account)),
            dispatch,
            'Location Created',
            'Failed To Create Location'
        );
        setIsDialogOpen(false);
    };

    if (!me || me.type !== Employee.TYPE_ADMIN) {
        return null;
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <LocationFormDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
                location={new Location()}
            />
            <Button onClick={() => setIsDialogOpen(true)} variant="soft">
                <AddIcon/>
                Add
            </Button>
        </Box>
    );
}

export default AddLocationButton;
