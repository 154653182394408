import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/joy';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import OpenIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import activityActions from 'actions/activityActions';
import { Activity } from 'domain';
import CommonUtils from "utils/CommonUtils";

import ActivityFormDialog from './ActivityFormDialog';

const ActivityActionMenu = ({ activity, enableCheckout }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activityId } = useParams();
    const me = useSelector(({ employees }) => employees.me);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const isViewingActivity = activityId === activity.id;

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpen = () => {
        navigate(`/activity/${activity.id}`);
        handleMenuClose();
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        handleMenuClose();
    };

    const _handleCheckInWithGeo = (geoPoint) => {
        const updatedActivity = new Activity(activity
            .set('checkin', new Date())
            .set('status', Activity.STATUS_IN_PROGRESS)
            .set('checkinGeo', geoPoint)
        );
        updateActivity(updatedActivity);
    };

    const handleCheckIn = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    _handleCheckInWithGeo({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                () => {
                    _handleCheckInWithGeo(null);
                },
                { enableHighAccuracy: true }
            );
        } else {
            _handleCheckInWithGeo(null);
        }
    };

    const _handleCheckOutWithGeo = (geoPoint) => {
        const updatedActivity = new Activity(activity
            .set('checkout', new Date())
            .set('status', Activity.STATUS_COMPLETE)
            .set('checkoutGeo', geoPoint)
        );
        updateActivity(updatedActivity);
    };

    const handleCheckOut = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    _handleCheckOutWithGeo({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                () => {
                    _handleCheckOutWithGeo(null);
                },
                { enableHighAccuracy: true }
            );
        } else {
            _handleCheckOutWithGeo(null);
        }
    };

    const updateActivity = (updatedActivity) => {
        CommonUtils.dispatcher(
            activityActions.save(updatedActivity),
            dispatch,
            'Activity Updated',
            'Failed To Update Activity'
        );
        handleMenuClose();
    };

    const handleSave = (updatedActivity) => {
        updateActivity(updatedActivity);
        setIsDialogOpen(false);
    };

    if (!me) {
        return null;
    }

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <ClickAwayListener onClickAway={handleMenuClose}>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    {!isViewingActivity && (
                        <MenuItem onClick={handleOpen}>
                            <OpenIcon /> View
                        </MenuItem>
                    )}
                    <MenuItem onClick={handleEdit}>
                        <EditIcon /> Edit
                    </MenuItem>
                    {activity.status === Activity.STATUS_PENDING && (
                        <MenuItem onClick={handleCheckIn}>
                            <CheckIcon /> Check In
                        </MenuItem>
                    )}
                    {activity.status === Activity.STATUS_IN_PROGRESS && (
                        <MenuItem onClick={handleCheckOut} disabled={!enableCheckout}>
                            <ExitToAppIcon /> Check Out
                        </MenuItem>
                    )}
                </Menu>
            </ClickAwayListener>
            <ActivityFormDialog
                key={activity.id}
                activity={activity}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
            />
        </>
    );
};

ActivityActionMenu.propTypes = {
    activity: PropTypes.instanceOf(Activity).isRequired,
    enableCheckout: PropTypes.bool,
};

ActivityActionMenu.defaultProps = {
    enableCheckout: true,
}

export default ActivityActionMenu;
