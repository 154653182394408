import React from 'react';
import PropTypes from 'prop-types';
import {Modal, DialogTitle, DialogContent, ModalDialog, DialogActions, Button, Alert} from '@mui/joy';
import WarningIcon from "@mui/icons-material/Warning";
import {Location} from "domain";

const ActivityPhotoPrompt = ({ open, onClose, onAddPhotos, photoType, location }) => {
    const handleAddPhotos = () => {
        onAddPhotos(photoType);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <DialogTitle>
                    {photoType === 'before' ? 'Add Before Photo' : 'Add After Photo'}
                </DialogTitle>
                <DialogContent>
                    No {photoType === 'before' ? 'before' : 'after'} photos found.
                    Would you like to add them photos now?
                    {location?.isPhotosRequired ? (
                        <Alert
                            color="warning"
                            variant="soft"
                            startDecorator={<WarningIcon />}
                        >
                            {`Location Requirements: ${location.getPhotoRequirementText()}`}
                        </Alert>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button variant="plain" onClick={onClose}>
                        Decline
                    </Button>
                    <Button variant="solid" onClick={handleAddPhotos}>
                        Add Photos
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

ActivityPhotoPrompt.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddPhotos: PropTypes.func.isRequired,
    location: PropTypes.instanceOf(Location).isRequired,
    photoType: PropTypes.oneOf(['before', 'after'])
};

ActivityPhotoPrompt.defaultProps = {
    photoType: null
};

export default ActivityPhotoPrompt;
