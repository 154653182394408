import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Stack, Button } from '@mui/joy';
import PropTypes from 'prop-types';
import StopIcon from '@mui/icons-material/Stop';
import EditIcon from '@mui/icons-material/Edit';

import { Event } from 'domain';
import CommonUtils from 'utils/CommonUtils';
import eventActions from 'actions/eventActions';
import EventFormDialog from './EventFormDialog';


const EventDetails = ({ event }) => {
    const { start, end, description } = event;
    const dispatch = useDispatch();
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);

    const handleEndEvent = () => {
        CommonUtils.dispatcher(
            eventActions.save(event.set('end', new Date())),
            dispatch,
            'Event Ended',
            'Failed To End Event',
        )
    };

    const toggleEditDialog = () => {
        setEditDialogOpen(!isEditDialogOpen);
    };

    const handleSaveEvent = (updatedEvent) => {
        CommonUtils.dispatcher(
            eventActions.save(updatedEvent),
            dispatch,
            'Event Updated',
            'Failed To Update Event',
        );
        setEditDialogOpen(false);
    };

    return (
        <Box>
            <Stack gap={2}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography level="h4" component="h1">
                        {CommonUtils.formatDateRange(start, end)}
                        <Button onClick={toggleEditDialog} variant="plain">
                            <EditIcon />
                        </Button>
                    </Typography>
                    {!end && <Button
                        onClick={handleEndEvent}
                        variant="soft"
                        color="danger"
                        startDecorator={<StopIcon/>}
                    >
                        End Event
                    </Button>}
                </Box>
                <Typography level="body1" color="text.secondary" gutterBottom>
                    {description}
                </Typography>
            </Stack>
            <EventFormDialog
                open={isEditDialogOpen}
                onClose={toggleEditDialog}
                onSave={handleSaveEvent}
                event={event}
            />
        </Box>
    );
};

EventDetails.propTypes = {
    event: PropTypes.instanceOf(Event),
};

export default EventDetails;
