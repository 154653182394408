import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Box, Button } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";

import CommonUtils from "utils/CommonUtils";
import employeeActions from "actions/employeeActions";
import { Employee } from 'domain';

import EmployeeFormDialog from "./EmployeeFormDialog";

const AddEmployeeButton = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const me = useSelector(({ employees }) => employees.me);
    const dispatch = useDispatch();

    const handleSave = (myEmployee) => {
        CommonUtils.dispatcher(
            employeeActions.add(myEmployee.set('account', me.account)),
            dispatch,
            'Employee Created',
            'Failed To Create Employee'
        );
        setIsDialogOpen(false);
    };

    if (!me || me.type !== Employee.TYPE_ADMIN) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <EmployeeFormDialog
                key="new-employee"
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onSave={handleSave}
            />
            <Button onClick={() => setIsDialogOpen(true)} variant="soft">
                <AddIcon />
                Add
            </Button>
        </Box>
    );
}

export default AddEmployeeButton;
