import React from 'react';
import PropTypes from 'prop-types';
import {Box, Skeleton} from '@mui/joy';

import { ActivityArray, Activity } from 'domain';

import ActivityListItem from "components/activity/ActivityListItem";
import ActivityListItemSkeleton from "components/activity/ActivityListItemSkeleton";
import ItemLoader from "components/ItemLoader";

const ActivityList = ({ activities, loading, onSelect, onMore, isMore }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {loading && Array(3).fill(0).map((v, ix) => (
            <Skeleton key={ix} width='100%' height={128} sx={{ borderRadius: 12, position: 'relative' }} />
        ))}
        {activities.map((activity, ix) => activity.id != null ? (
            <ActivityListItem
                key={activity.id ?? ix}
                activity={activity}
                isInProgress={activity.status === Activity.STATUS_IN_PROGRESS}
                onSelect={onSelect}
            />
        ) : (
            <ActivityListItemSkeleton key={activity.id ?? ix} />
        ))}
        <ItemLoader onMore={onMore} enabled={isMore}>
            <ActivityListItemSkeleton />
        </ItemLoader>
    </Box>
);

ActivityList.propTypes = {
    activities: PropTypes.instanceOf(ActivityArray).isRequired,
    onSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onMore: PropTypes.func,
    isMore: PropTypes.bool,
};

ActivityList.defaultProps = {
    loading: false,
    onMore: () => {},
    isMore: false,
};

export default ActivityList;
